import React, { useContext, useState } from "react";
import { Button, LoadingButton } from "../../../../../../components/form";
import {
  payLaterCardIcon,
  payNowCardIcon,
} from "../../../../../../assets/icons";
import { GlobalOrderContext } from "../../../../../../context/OrderContext";
import {
  createOrderAPI,
  getOrderIdForPaymentAPI,
} from "../../../../../../services/user-controllers/order-controller";
import { toast } from "react-toastify";
import CustomModal from "components/modals/CustomModal";
import { SuceesModalImg, colorCardImg } from "assets/images";
import { useNavigate } from "react-router-dom";
import RazorPayPayment from "../../razorpay-payment/RazorPayPayment";
import { useRazorpay } from "react-razorpay";
import WalletPayHandler from "./WalletPayHandler";
import moment from "moment";

const OrderPaymentDetails = ({ prev, onClick }) => {
  const reDirect = useNavigate();

  const [payLaterModal, setPayLaterModal] = useState(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [isWalletPayModalOpen, setIsWalletPayModalOpen] = useState(false);
  const [isSuccessOrderModalOpen, setIsSuccessOrderModalOpen] = useState(false);

  const { error, isLoading, Razorpay } = useRazorpay();
  const [orderIdForPayment, setOrderIdForPayment] = useState("");

  const [orderDetailsAfterPlaced, setOrderDetailsAfterPlaced] = useState({});

  //************************** Handle all state and variables **********************//
  const {
    vendorDetail,
    orderDetailsForm,
    setOrderDetailsForm,
    orderSearchFieldData,
    orderDetailsForPayment,
    setOrderWalletToggle,
    orderWalletToggle,

    extraPickUpChargesWithFloor,
    setExtraPickUpChargesWithFloor,

    extraDeliveryChargesWithFloor,
    setExtraDeliveryChargesWithFloor,

    vendorDetailsWithExtraCharge,
    setVendorDetailsWithExtraCharge,

    extraChargesDelivery,
    setExtraChargesDelivery,

    selectedPickUpFloorChargeName,
    setSelectedPickUpFloorChargeName,
    selectedDeliveryFloorChargeName,
    setSelectedDeliveryFloorChargeName,
  } = useContext(GlobalOrderContext);

  const orderAmount = vendorDetail?.totalAmount;
  const [paymentMethod, setPaymentMethod] = useState("");
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);
  const [walletButtonLoading, setWalletButtonLoading] = useState(false);

  //***************************** Handle all functions *****************************//

  // submitButtonDisableAndClassHandler
  const submitButtonDisableHandler = (callRole) => {
    if (callRole === "disable") {
      if (!paymentMethod?.length && paymentMethod !== "payLater") {
        return true;
      } else if (submitButtonLoading) {
        return true;
      } else {
        return false;
      }
    } else {
      if (!paymentMethod?.length && paymentMethod !== "payLater") {
        return "disable";
      } else if (submitButtonLoading) {
        return "disable";
      } else {
        return "";
      }
    }
  };

  // setPaymentModeHandler
  const setPaymentModeHandler = (e, type) => {
    const { checked } = e.target;
    if (checked && type === "payLater") {
      setPaymentMethod("payLater");
    } else if (checked && type === "payNow") {
      setPaymentMethod("payNow");
    } else if (checked && type === "payWallet") {
      setPaymentMethod("payWallet");
    } else {
      setPaymentMethod("");
    }
  };

  // Function for get orderId
  const getOrderIdForPayment = async () => {
    try {
      let sendData = {
        totalAmount: orderDetailsForPayment?.orderAmount,
      };
      console.log("I AM SEND DATA FOR PAYMENT", sendData);
      const { data } = await getOrderIdForPaymentAPI(sendData);
      if (data?.code === 200) {
        setOrderIdForPayment(data?.data?.gatewayOrderId);
        return data?.data?.gatewayOrderId;
      } else {
        toast.error(data?.message);
      }
    } catch (err) {
      console.error(err);
    }
  };

  // function for create order by paylater
  const createOrderHandler = async (e) => {
    e.preventDefault();
    setSubmitButtonLoading(true);
    let mergeAllData = {
      details: { ...vendorDetail },
      ...orderSearchFieldData,
      ...orderDetailsForm.pickupDetailsForm,
      ...orderDetailsForm.deliveryDetailsForm,
      ...orderDetailsForm.returnDetailsForm,
      ...orderDetailsForm.orderDetailsFormData,
    };

    console.log("MERGE DATA==>>>", mergeAllData);

    mergeAllData = {
      ...mergeAllData,
      ItemCategory: mergeAllData?.ItemCategory?.map((el) => el?.value)?.join(
        ", "
      ),
      ItemType: mergeAllData?.ItemType?.map((el) => el?.value)?.join(", "),
    };

    console.log("I am MERGE DATA", mergeAllData);

    let sendData = {};
    sendData = new FormData();
    const {
      items,
      details,
      ItemType,
      breakups,
      invoiceImage,
      ItemCategory,
      ...rest
    } = mergeAllData;

    Object.keys(rest).forEach((key) => {
      sendData.append(key, rest[key]);
    });

    sendData.append("ItemType", ItemType);
    sendData.append("paymentMode", "Paylater");
    sendData.append("ItemCategory", ItemCategory);
    sendData.append("invoiceImage", invoiceImage);
    sendData.append("items", JSON?.stringify(items));
    sendData.append("details", JSON.stringify(details));
    // sendData.append("breakups", JSON.stringify(breakups));

    if (extraPickUpChargesWithFloor?.isMallPickUp) {
      sendData.append(
        "pickupmallCharge",
        vendorDetailsWithExtraCharge?.mallPickUp
      );
    }

    if (extraPickUpChargesWithFloor?.isCsdPickUp) {
      sendData.append(
        "pickupcsdCharge",
        vendorDetailsWithExtraCharge?.csdPickUp
      );
    }

    if (extraPickUpChargesWithFloor?.isSundayPickUp) {
      sendData.append(
        "pickupsundayCharge",
        vendorDetailsWithExtraCharge?.sundayPickUp
      );
      sendData.append(
        "pickupsundayDate",
        moment(extraPickUpChargesWithFloor?.pickupsundayDate).format(
          "YYYY-MM-DD"
        )
      );
    }

    if (selectedPickUpFloorChargeName?.floorName?.length > 0) {
      sendData.append("pickupfloor", selectedPickUpFloorChargeName?.floorName);

      sendData.append(
        "pickupfloorCharge",
        vendorDetailsWithExtraCharge?.floorPickUp
      );
    }

    if (extraDeliveryChargesWithFloor?.isMallDelivery) {
      sendData.append(
        "deliverymallCharge",
        vendorDetailsWithExtraCharge?.mallDelivery
      );
    }

    if (extraDeliveryChargesWithFloor?.isCsdDelivery) {
      sendData.append(
        "deliverycsdCharge",
        vendorDetailsWithExtraCharge?.csdDelivery
      );
    }

    if (extraDeliveryChargesWithFloor?.isSundayDelivery) {
      sendData.append(
        "deliverysundayCharge",
        vendorDetailsWithExtraCharge?.sundayDelivery
      );
      sendData.append(
        "deliverysundayDate",
        moment(extraDeliveryChargesWithFloor?.deliverysundayDate).format(
          "YYYY-MM-DD"
        )
      );
    }

    if (extraChargesDelivery?.deliveryType === "appointment") {
      sendData.append(
        "deliveryappointmentAt",
        moment(extraChargesDelivery?.selectedAppointmentDate).format(
          "YYYY-MM-DD"
        )
      );
    }

    if (vendorDetailsWithExtraCharge?.appointmentDelivery) {
      sendData.append(
        "deliveryappointmentCharge",
        vendorDetailsWithExtraCharge?.appointmentDelivery
      );
    }

    if (selectedDeliveryFloorChargeName?.floorName?.length > 0) {
      sendData.append(
        "deliveryfloor",
        selectedDeliveryFloorChargeName?.floorName
      );

      sendData.append(
        "deliveryfloorCharge",
        vendorDetailsWithExtraCharge?.floorDelivery
      );
    }

    console.log("I AM FINAL SEND DATA", sendData);

    try {
      const { data } = await createOrderAPI(sendData);

      console.log("I AM ORDER DATA PAY LATER  ==---===--->> ", data);

      if (data?.code === 200) {
        setOrderDetailsAfterPlaced(data?.data);
        toast.success(data?.message);
        setOrderDetailsForm({});
        setExtraPickUpChargesWithFloor({
          isMallPickUp: false,
          isCsdPickUp: false,
          isSundayPickUp: false,
          isFloorPickUp: false,
          pickUpFloorName: "",
          floorChargesList: [],
        });

        setExtraDeliveryChargesWithFloor({
          isMallDelivery: false,
          isCsdDelivery: false,
          isSundayDelivery: false,
          isFloorDelivery: false,
          deliveryFloorName: "",
          floorChargesList: [],
        });

        setVendorDetailsWithExtraCharge({
          mallPickUp: null,
          csdPickUp: null,
          sundayPickUp: null,
          floorPickUp: null,

          mallDelivery: null,
          csdDelivery: null,
          sundayDelivery: null,
          floorDelivery: null,
          appointmentDelivery: null,
        });

        setExtraChargesDelivery({
          deliveryType: "",
          selectedAppointmentDate: "",
        });

        setSelectedPickUpFloorChargeName({});
        setSelectedDeliveryFloorChargeName({});
        localStorage.removeItem("orderSearchDetail");
        localStorage.removeItem("vendorDetailsForOrder");
        setIsSuccessOrderModalOpen(true);
        // reDirect(`/user/dashboard/my-orders`);
      } else {
        toast.error(data?.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setSubmitButtonLoading(false);
    }
  };

  // function for create order by razor pay
  // const createOrderHandlerForRazorPay = async (paymentResponse) => {
  //   // e.preventDefault();
  //   setSubmitButtonLoading(true);
  //   let mergeAllData = {
  //     details: { ...vendorDetail },
  //     ...orderSearchFieldData,
  //     ...orderDetailsForm.pickupDetailsForm,
  //     ...orderDetailsForm.deliveryDetailsForm,
  //     ...orderDetailsForm.returnDetailsForm,
  //     ...orderDetailsForm.orderDetailsFormData,
  //   };

  //   mergeAllData = {
  //     ...mergeAllData,
  //     ItemCategory: mergeAllData?.ItemCategory?.map((el) => el?.value)?.join(
  //       ", "
  //     ),
  //     ItemType: mergeAllData?.ItemType?.map((el) => el?.value)?.join(", "),
  //   };

  //   let sendData = {};
  //   sendData = new FormData();
  //   const {
  //     items,
  //     details,
  //     ItemType,
  //     breakups,
  //     invoiceImage,
  //     ItemCategory,
  //     ...rest
  //   } = mergeAllData;

  //   Object.keys(rest).forEach((key) => {
  //     sendData.append(key, rest[key]);
  //   });

  //   sendData.append("ItemType", ItemType);
  //   sendData.append("paymentMode", "Gateway");
  //   sendData.append("ItemCategory", ItemCategory);
  //   sendData.append("invoiceImage", invoiceImage);
  //   sendData.append("items", JSON?.stringify(items));
  //   sendData.append("details", JSON.stringify(details));

  //   sendData.append("gatewayOrderId", paymentResponse.razorpay_order_id);
  //   sendData.append("payment_id", paymentResponse.razorpay_payment_id);
  //   sendData.append("signature", paymentResponse.razorpay_signature);

  //   // sendData.append("breakups", JSON.stringify(breakups));

  //   console.log("I AM FINAL SEND DATA", sendData);

  //   try {
  //     const { data } = await createOrderAPI(sendData);
  //     if (data?.code === 200) {
  //       toast.success(data?.message);
  //       setOrderDetailsForm({});
  //       localStorage.removeItem("orderSearchDetail");
  //       localStorage.removeItem("vendorDetailsForOrder");
  //       reDirect(`/user/dashboard/my-orders`);
  //     } else {
  //       toast.error(data?.message);
  //     }
  //   } catch (err) {
  //     console.error(err);
  //   } finally {
  //     setSubmitButtonLoading(false);
  //   }
  // };

  // function for create order by razor pay

  const createOrderHandlerForRazorPay = async () => {
    // e.preventDefault();
    setSubmitButtonLoading(true);
    let mergeAllData = {
      details: { ...vendorDetail },
      ...orderSearchFieldData,
      ...orderDetailsForm.pickupDetailsForm,
      ...orderDetailsForm.deliveryDetailsForm,
      ...orderDetailsForm.returnDetailsForm,
      ...orderDetailsForm.orderDetailsFormData,
    };

    mergeAllData = {
      ...mergeAllData,
      ItemCategory: mergeAllData?.ItemCategory?.map((el) => el?.value)?.join(
        ", "
      ),
      ItemType: mergeAllData?.ItemType?.map((el) => el?.value)?.join(", "),
    };

    let sendData = {};
    sendData = new FormData();
    const {
      items,
      details,
      ItemType,
      breakups,
      invoiceImage,
      ItemCategory,
      ...rest
    } = mergeAllData;

    Object.keys(rest).forEach((key) => {
      sendData.append(key, rest[key]);
    });

    sendData.append("ItemType", ItemType);
    sendData.append("paymentMode", "Gateway");
    sendData.append("ItemCategory", ItemCategory);
    sendData.append("invoiceImage", invoiceImage);
    sendData.append("items", JSON?.stringify(items));
    sendData.append("details", JSON.stringify(details));

    if (extraPickUpChargesWithFloor?.isMallPickUp) {
      sendData.append(
        "pickupmallCharge",
        vendorDetailsWithExtraCharge?.mallPickUp
      );
    }

    if (extraPickUpChargesWithFloor?.isCsdPickUp) {
      sendData.append(
        "pickupcsdCharge",
        vendorDetailsWithExtraCharge?.csdPickUp
      );
    }

    if (extraPickUpChargesWithFloor?.isSundayPickUp) {
      sendData.append(
        "pickupsundayCharge",
        vendorDetailsWithExtraCharge?.sundayPickUp
      );

      sendData.append(
        "pickupsundayDate",
        moment(extraPickUpChargesWithFloor?.pickupsundayDate).format(
          "YYYY-MM-DD"
        )
      );
    }

    if (selectedPickUpFloorChargeName?.floorName?.length > 0) {
      sendData.append("pickupfloor", selectedPickUpFloorChargeName?.floorName);

      sendData.append(
        "pickupfloorCharge",
        vendorDetailsWithExtraCharge?.floorPickUp
      );
    }

    if (extraDeliveryChargesWithFloor?.isMallDelivery) {
      sendData.append(
        "deliverymallCharge",
        vendorDetailsWithExtraCharge?.mallDelivery
      );
    }

    if (extraDeliveryChargesWithFloor?.isCsdDelivery) {
      sendData.append(
        "deliverycsdCharge",
        vendorDetailsWithExtraCharge?.csdDelivery
      );
    }

    if (extraDeliveryChargesWithFloor?.isSundayDelivery) {
      sendData.append(
        "deliverysundayCharge",
        vendorDetailsWithExtraCharge?.sundayDelivery
      );
      sendData.append(
        "deliverysundayDate",
        moment(extraDeliveryChargesWithFloor?.deliverysundayDate).format(
          "YYYY-MM-DD"
        )
      );
    }

    if (extraChargesDelivery?.deliveryType === "appointment") {
      sendData.append(
        "deliveryappointmentAt",
        moment(extraChargesDelivery?.selectedAppointmentDate).format(
          "YYYY-MM-DD"
        )
      );
    }

    if (vendorDetailsWithExtraCharge?.appointmentDelivery) {
      sendData.append(
        "deliveryappointmentCharge",
        vendorDetailsWithExtraCharge?.appointmentDelivery
      );
    }

    if (selectedDeliveryFloorChargeName?.floorName?.length > 0) {
      sendData.append(
        "deliveryfloor",
        selectedDeliveryFloorChargeName?.floorName
      );

      sendData.append(
        "deliveryfloorCharge",
        vendorDetailsWithExtraCharge?.floorDelivery
      );
    }

    // sendData.append("gatewayOrderId", paymentResponse.razorpay_order_id);
    // sendData.append("payment_id", paymentResponse.razorpay_payment_id);
    // sendData.append("signature", paymentResponse.razorpay_signature);

    // sendData.append("breakups", JSON.stringify(breakups));

    console.log("I AM FINAL SEND DATA", sendData);

    try {
      const { data } = await createOrderAPI(sendData);
      if (data?.code === 200) {
        console.log("PayNow response", data);
        setOrderDetailsAfterPlaced(data?.data);
        displayRazorpay(data?.data);
      } else {
        toast.error(data?.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setSubmitButtonLoading(false);
    }
  };

  // Razor pay Payment Function
  const displayRazorpay = async (gateWayResp) => {
    if (gateWayResp?.gatewayOrderId) {
      const options = {
        key: process.env.REACT_APP_RAZOR_PAY_KEY,
        amount: gateWayResp?.amount,
        currency: gateWayResp?.currency,
        name: "Test Company",
        description: "Test Transaction",
        order_id: gateWayResp?.gatewayOrderId,

        prefill: {
          name: "John Doe",
          email: "john.doe@example.com",
          contact: "9999999999",
          method: "card|upi|wallet", //card|upi|wallet
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#F37254",
        },

        handler: function (response) {
          // Hit the API to create the order in the backend
          toast.success("Order Booked Successfully!");
          setOrderDetailsForm({});

          setExtraPickUpChargesWithFloor({
            isMallPickUp: false,
            isCsdPickUp: false,
            isSundayPickUp: false,
            isFloorPickUp: false,
            pickUpFloorName: "",
            floorChargesList: [],
          });

          setExtraDeliveryChargesWithFloor({
            isMallDelivery: false,
            isCsdDelivery: false,
            isSundayDelivery: false,
            isFloorDelivery: false,
            deliveryFloorName: "",
            floorChargesList: [],
          });

          setVendorDetailsWithExtraCharge({
            mallPickUp: null,
            csdPickUp: null,
            sundayPickUp: null,
            floorPickUp: null,

            mallDelivery: null,
            csdDelivery: null,
            sundayDelivery: null,
            floorDelivery: null,
            appointmentDelivery: null,
          });

          setExtraChargesDelivery({
            deliveryType: "",
            selectedAppointmentDate: "",
          });

          setSelectedPickUpFloorChargeName({});
          setSelectedDeliveryFloorChargeName({});

          localStorage.removeItem("orderSearchDetail");
          localStorage.removeItem("vendorDetailsForOrder");

          setIsSuccessOrderModalOpen(true);

          // reDirect(`/user/dashboard/my-orders`);
        },
      };

      const razorpayInstance = new Razorpay(options);
      razorpayInstance.open();
    }
  };

  // function for create order by Wallet
  const createOrderHandlerForWallet = async (e) => {
    e.preventDefault();
    setWalletButtonLoading(true);
    let mergeAllData = {
      details: { ...vendorDetail },
      ...orderSearchFieldData,
      ...orderDetailsForm.pickupDetailsForm,
      ...orderDetailsForm.deliveryDetailsForm,
      ...orderDetailsForm.returnDetailsForm,
      ...orderDetailsForm.orderDetailsFormData,
    };

    mergeAllData = {
      ...mergeAllData,
      ItemCategory: mergeAllData?.ItemCategory?.map((el) => el?.value)?.join(
        ", "
      ),
      ItemType: mergeAllData?.ItemType?.map((el) => el?.value)?.join(", "),
    };

    let sendData = {};
    sendData = new FormData();
    const {
      items,
      details,
      ItemType,
      breakups,
      invoiceImage,
      ItemCategory,
      ...rest
    } = mergeAllData;

    Object.keys(rest).forEach((key) => {
      sendData.append(key, rest[key]);
    });

    sendData.append("ItemType", ItemType);
    sendData.append("paymentMode", "Wallet");
    sendData.append("ItemCategory", ItemCategory);
    sendData.append("invoiceImage", invoiceImage);
    sendData.append("items", JSON?.stringify(items));
    sendData.append("details", JSON.stringify(details));

    if (extraPickUpChargesWithFloor?.isMallPickUp) {
      sendData.append(
        "pickupmallCharge",
        vendorDetailsWithExtraCharge?.mallPickUp
      );
    }

    if (extraPickUpChargesWithFloor?.isCsdPickUp) {
      sendData.append(
        "pickupcsdCharge",
        vendorDetailsWithExtraCharge?.csdPickUp
      );
    }

    if (extraPickUpChargesWithFloor?.isSundayPickUp) {
      sendData.append(
        "pickupsundayCharge",
        vendorDetailsWithExtraCharge?.sundayPickUp
      );
      sendData.append(
        "pickupsundayDate",
        moment(extraPickUpChargesWithFloor?.pickupsundayDate).format(
          "YYYY-MM-DD"
        )
      );
    }

    if (selectedPickUpFloorChargeName?.floorName?.length > 0) {
      sendData.append("pickupfloor", selectedPickUpFloorChargeName?.floorName);

      sendData.append(
        "pickupfloorCharge",
        vendorDetailsWithExtraCharge?.floorPickUp
      );
    }

    if (extraDeliveryChargesWithFloor?.isMallDelivery) {
      sendData.append(
        "deliverymallCharge",
        vendorDetailsWithExtraCharge?.mallDelivery
      );
    }

    if (extraDeliveryChargesWithFloor?.isCsdDelivery) {
      sendData.append(
        "deliverycsdCharge",
        vendorDetailsWithExtraCharge?.csdDelivery
      );
    }

    if (extraDeliveryChargesWithFloor?.isSundayDelivery) {
      sendData.append(
        "deliverysundayCharge",
        vendorDetailsWithExtraCharge?.sundayDelivery
      );
      sendData.append(
        "deliverysundayDate",
        moment(extraDeliveryChargesWithFloor?.deliverysundayDate).format(
          "YYYY-MM-DD"
        )
      );
    }

    if (extraChargesDelivery?.deliveryType === "appointment") {
      sendData.append(
        "deliveryappointmentAt",
        moment(extraChargesDelivery?.selectedAppointmentDate).format(
          "YYYY-MM-DD"
        )
      );
    }

    if (vendorDetailsWithExtraCharge?.appointmentDelivery) {
      sendData.append(
        "deliveryappointmentCharge",
        vendorDetailsWithExtraCharge?.appointmentDelivery
      );
    }

    if (selectedDeliveryFloorChargeName?.floorName?.length > 0) {
      sendData.append(
        "deliveryfloor",
        selectedDeliveryFloorChargeName?.floorName
      );

      sendData.append(
        "deliveryfloorCharge",
        vendorDetailsWithExtraCharge?.floorDelivery
      );
    }

    // sendData.append("gatewayOrderId", paymentResponse.razorpay_order_id);
    // sendData.append("payment_id", paymentResponse.razorpay_payment_id);
    // sendData.append("signature", paymentResponse.razorpay_signature);

    // sendData.append("breakups", JSON.stringify(breakups));

    console.log("I AM FINAL SEND DATA", sendData);

    try {
      const { data } = await createOrderAPI(sendData);

      if (data?.code === 200) {

        setOrderDetailsAfterPlaced(data?.data);

        setOrderDetailsForm({});
        setExtraPickUpChargesWithFloor({
          isMallPickUp: false,
          isCsdPickUp: false,
          isSundayPickUp: false,
          isFloorPickUp: false,
          pickUpFloorName: "",
          floorChargesList: [],
        });

        setExtraDeliveryChargesWithFloor({
          isMallDelivery: false,
          isCsdDelivery: false,
          isSundayDelivery: false,
          isFloorDelivery: false,
          deliveryFloorName: "",
          floorChargesList: [],
        });

        setVendorDetailsWithExtraCharge({
          mallPickUp: null,
          csdPickUp: null,
          sundayPickUp: null,
          floorPickUp: null,

          mallDelivery: null,
          csdDelivery: null,
          sundayDelivery: null,
          floorDelivery: null,
          appointmentDelivery: null,
        });

        setExtraChargesDelivery({
          deliveryType: "",
          selectedAppointmentDate: "",
        });

        setSelectedPickUpFloorChargeName({});
        setSelectedDeliveryFloorChargeName({});

        localStorage.removeItem("orderSearchDetail");
        localStorage.removeItem("vendorDetailsForOrder");

        setIsWalletPayModalOpen(false);
        setIsSuccessOrderModalOpen(true);
        toast.success(data?.message);

        setOrderWalletToggle(!orderWalletToggle);

        // reDirect(`/user/dashboard/my-orders`);
      } else {
        toast.error(data?.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setWalletButtonLoading(false);
    }
  };

  //******************************* Handle HTML CODE *******************************//
  return (
    <>
      <h4>Payment Option</h4>

      <form>
        <p>Choose a payment option</p>

        <div className="form_field_wrap paymentMethod">
          <div className="form_field">
            <label className="">
              <input
                id="payNow"
                type="radio"
                name="paymentMethod"
                checked={paymentMethod === "payNow"}
                onChange={(e) => setPaymentModeHandler(e, "payNow")}
              />
              <div className="content">
                <img src={payNowCardIcon} alt="pay now" />
                <span>PAY Now</span>
              </div>
            </label>
          </div>

          <div className="form_field mx-1">
            <label className="">
              <input
                id="walletPay"
                type="radio"
                name="paymentMethod"
                checked={paymentMethod === "payWallet"}
                onChange={(e) => setPaymentModeHandler(e, "payWallet")}
              />
              <div className="content">
                <img src={payNowCardIcon} alt="pay now" />
                <span>PAY With Wallet</span>
              </div>
            </label>
          </div>

          {vendorDetail?.userType === "postpaid" && (
            <div className="form_field">
              <label className="">
                <input
                  type="radio"
                  id="payNow"
                  name="paymentMethod"
                  checked={paymentMethod === "payLater"}
                  onChange={(e) => setPaymentModeHandler(e, "payLater")}
                />
                <div className="content">
                  <img src={payLaterCardIcon} alt="pay now" />
                  <span>PAY LATER</span>
                </div>
              </label>
            </div>
          )}
        </div>

        {/* PayLater Continue Button */}
        {paymentMethod === "payLater" && (
          <div className="button_wrap">
            <Button className={"light black"} onClick={prev}>
              Back
            </Button>

            <LoadingButton
              onClick={createOrderHandler}
              loading={submitButtonLoading}
              className={submitButtonDisableHandler("class")}
              disabled={submitButtonDisableHandler("disable")}
            >
              Continue
            </LoadingButton>
          </div>
        )}

        {paymentMethod === "payNow" && (
          <div className="button_wrap">
            <Button className={"light black"} onClick={prev}>
              Back
            </Button>

            {/* <Button onClick={displayRazorpay}>Pay Now</Button> */}

            <Button onClick={createOrderHandlerForRazorPay}>Pay Now</Button>

            {/* <LoadingButton
              loading={submitButtonLoading}
              onClick={createOrderHandler}
              className={submitButtonDisableHandler("class")}
              disabled={submitButtonDisableHandler("disable")}
            >
              Pay
            </LoadingButton> */}
          </div>
        )}

        {paymentMethod === "payWallet" && (
          <div className="button_wrap">
            <Button className={"light black"} onClick={prev}>
              Back
            </Button>

            <Button onClick={() => setIsWalletPayModalOpen(true)}>
              Continue
            </Button>

            {/* <LoadingButton
              loading={submitButtonLoading}
              onClick={createOrderHandler}
              className={submitButtonDisableHandler("class")}
              disabled={submitButtonDisableHandler("disable")}
            >
              Pay
            </LoadingButton> */}
          </div>
        )}
      </form>

      {/*--------- Wallet pay modal ---------*/}
      <CustomModal
        className={"payLaterModal"}
        show={isWalletPayModalOpen}
        handleClose={() => setIsWalletPayModalOpen(false)}
      >
        <WalletPayHandler
          orderAmount={orderAmount}
          loadingWallet={walletButtonLoading}
          onSubmitHandler={createOrderHandlerForWallet}
        />
      </CustomModal>

      {/*----- payment modal -----*/}
      <CustomModal
        show={isPaymentModalOpen}
        className={"payLaterModal"}
        handleClose={() => setIsPaymentModalOpen(false)}
      >
        <RazorPayPayment />
        <div className="button_wrap">
          <Button className={"w-100"}>Continue</Button>
        </div>
      </CustomModal>

      {/*------------- Order Successful modal ----------*/}
      <CustomModal
        className={"payLaterModal"}
        show={isSuccessOrderModalOpen}
        handleClose={() => {
          reDirect(`/user/dashboard/my-orders`);
          setIsSuccessOrderModalOpen(false);
        }}
      >
        <img src={SuceesModalImg} alt="" />
        <h4>Payment Successful</h4>
        <p>Congratulations! Your Order has been Successfully place. </p>

        {paymentMethod === "payLater" && (
          <ul>
            <li>
              <span>REMAINING BALANCE</span>
              <b>₹{orderDetailsAfterPlaced?.availableAmount || "----"}</b>
            </li>
            <li>
              <span>TOTAL AMOUNT</span>
              <b>₹{orderDetailsAfterPlaced?.totalAmount || "----"}</b>
            </li>
            <li>
              <span>ORDER ID</span>
              <b>{orderDetailsAfterPlaced?.orderId || "----"}</b>
            </li>
          </ul>
        )}
        
        {paymentMethod === "payNow" && (
          <ul>
            {/* <li>
              <span>REMAINING BALANCE</span>
              <b>₹{orderDetailsAfterPlaced?.availableAmount || "----"}</b>
            </li> */}
            <li>
              <span>TOTAL AMOUNT</span>
              <b>₹{orderDetailsAfterPlaced?.totalAmount || "----"}</b>
            </li>
            <li>
              <span>ORDER ID</span>
              <b>{orderDetailsAfterPlaced?.orderId || "----"}</b>
            </li>
          </ul>
        )}

        <div className="">
          <p>
            <b>To show your Order</b>
          </p>
          <Button
            className={"w-100"}
            onClick={() => reDirect(`/user/dashboard/my-orders`)}
          >
            Click Here
          </Button>
        </div>
      </CustomModal>
    </>
  );
};

export default OrderPaymentDetails;
