import { createContext, useEffect, useState } from "react";

export const GlobalOrderContext = createContext();

const OrderContext = ({ children }) => {
  const [vendorDetail, setVendorDetail] = useState({});
  const [orderDetailsForm, setOrderDetailsForm] = useState(undefined);
  const [orderSearchFieldData, setOrderSearchFieldData] = useState({});
  const [orderDetailsForPayment, setOrderDetailsForPayment] = useState({});

  const [extraPickUpChargesWithFloor, setExtraPickUpChargesWithFloor] =
    useState({
      isMallPickUp: false,
      isCsdPickUp: false,
      isSundayPickUp: false,
      isFloorPickUp: false,
      pickUpFloorName: "",
      floorChargesList: [],
      pickupsundayDate: null,
    });

  const [extraDeliveryChargesWithFloor, setExtraDeliveryChargesWithFloor] =
    useState({
      isMallDelivery: false,
      isCsdDelivery: false,
      isSundayDelivery: false,
      isFloorDelivery: false,
      deliveryFloorName: "",
      deliverysundayDate: null,
      floorChargesList: [],
    });

  const [vendorDetailsWithExtraCharge, setVendorDetailsWithExtraCharge] =
    useState({
      mallPickUp: null,
      csdPickUp: null,
      sundayPickUp: null,
      floorPickUp: null,

      mallDelivery: null,
      csdDelivery: null,
      sundayDelivery: null,
      floorDelivery: null,
      appointmentDelivery: null,
    });

  const [extraChargesDelivery, setExtraChargesDelivery] = useState({
    deliveryType: "",
    selectedAppointmentDate: "",
  });

  const [orderIdForLabelDownload, setOrderIdForLabelDownload] = useState("");

  const [selectedPickUpFloorChargeName, setSelectedPickUpFloorChargeName] =
    useState({});

  const [selectedDeliveryFloorChargeName, setSelectedDeliveryFloorChargeName] =
    useState({});

  //*********************** Handle HTML CODE *********************//
  return (
    <GlobalOrderContext.Provider
      value={{
        vendorDetail,
        setVendorDetail,
        orderDetailsForm,
        setOrderDetailsForm,
        orderSearchFieldData,
        orderDetailsForPayment,
        setOrderSearchFieldData,
        setOrderDetailsForPayment,

        extraPickUpChargesWithFloor,
        setExtraPickUpChargesWithFloor,
        extraDeliveryChargesWithFloor,
        setExtraDeliveryChargesWithFloor,

        vendorDetailsWithExtraCharge,
        setVendorDetailsWithExtraCharge,

        extraChargesDelivery,
        setExtraChargesDelivery,

        selectedPickUpFloorChargeName,
        setSelectedPickUpFloorChargeName,
        selectedDeliveryFloorChargeName,
        setSelectedDeliveryFloorChargeName,
      }}
    >
      {children}
    </GlobalOrderContext.Provider>
  );
};

export default OrderContext;
