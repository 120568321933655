import "../auth.scss";
import React, { useState } from "react";
import {
  callIcon,
  lockIcon,
  messageIcon,
  profileIcon,
} from "../../../assets/icons";
import { Link } from "react-router-dom";
import { LuUsers } from "react-icons/lu";
import { authImg } from "../../../assets/images";
import { Button, Input, LoadingButton } from "../../../components/form";
import usePasswordToggle from "../../../hooks/usePasswordToggle";
import {
  otpSendApi,
  personSignUp,
} from "../../../services/auth-controllers/authCont";
import { toast } from "react-toastify";
import OTPComponent from "../OTP/MobileOTP";

const SignUp = ({ switchLogin }) => {
  //************************ Handle all states ************************//
  const [passwordType, showIcon] = usePasswordToggle();
  const [roleOfPerson, setRoleOfPerson] = useState("user");
  const [isOTPModalOpen, setIsOTPModalOpen] = useState(false);
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);

  const [signUpFormFields, setSignUpFormFields] = useState({
    name: "",
    email: "",
    mobile: "",
    password: "",
  });

  const [signUpFormFieldsValidationError, setSignUpFormFieldsValidationError] =
    useState({
      name: "",
      email: "",
      mobile: "",
      password: "",
    });

  const [formInfoError, setFormInfoError] = useState({
    name: false,
    email: false,
    mobile: false,
    password: false,
  });

  //************************ Handle all functions *********************//

  const handleOtpModalVisibility = () => {
    setIsOTPModalOpen(false);
  };

  // onChangeHandler
  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    if (name === "mobile") {
      // Replace any character that is not a digit (0-9) with an empty string
      const numericValue = value.replace(/[^0-9]/g, "");
      setSignUpFormFields({ ...signUpFormFields, mobile: numericValue });
    } else {
      setSignUpFormFields({ ...signUpFormFields, [name]: value });
    }
  };

  // Error Handler
  const errorHandler = () => {
    let errorField = {};
    let infoErrorFiled = {
      name: false,
      email: false,
      mobile: false,
      password: false,
    };
    let formIsValid = true;
    let numberFormat = /^\d{6,}$/;
    let mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    let passwordFormat =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%*~^&+=!]).{8,}$/;

    // /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%*~^&+=!]).{8,}$/;

    if (!signUpFormFields.name) {
      formIsValid = false;
      errorField["name"] = "Please Enter You Name/Trade Name *";
    }

    if (!signUpFormFields.email) {
      formIsValid = false;
      errorField["email"] = "Please Enter Your Email *";
    } else if (!signUpFormFields.email.match(mailFormat)) {
      formIsValid = false;
      errorField["email"] = "Invalid Email";
      infoErrorFiled["email"] = true;
    }

    if (!signUpFormFields.mobile) {
      formIsValid = false;
      errorField["mobile"] = "Please Enter Your Mobile Number *";
    } else if (!signUpFormFields.mobile.match(numberFormat)) {
      formIsValid = false;
      errorField["mobile"] = "Invalid Mobile";
      infoErrorFiled["mobile"] = true;
    } else if (signUpFormFields?.mobile?.length < 10) {
      formIsValid = false;
      errorField["mobile"] = "Invalid Mobile";
      infoErrorFiled["mobile"] = true;
    }

    if (!signUpFormFields.password) {
      formIsValid = false;
      errorField["password"] = "Please Enter Password *";
    } else if (!signUpFormFields.password.match(passwordFormat)) {
      formIsValid = false;
      errorField["password"] =
        "Password must has at least 8 characters that include at least 1 lowercase character, 1 uppercase characters, 1 number, and 1 special character in (!@#$%^&*)";
      infoErrorFiled["password"] = true;
    }

    setFormInfoError(infoErrorFiled);
    setSignUpFormFieldsValidationError(errorField);
    return formIsValid;
  };

  // send OTP handler
  const sendOTPHandler = async (e) => {
    e.preventDefault();
    if (errorHandler()) {
      try {
        setSubmitButtonLoading(true);
        // const { data } = await otpSendApi({ email: signUpFormFields?.email });
        const { data } = await otpSendApi({ email: signUpFormFields?.email });
        if (data.code === 200) {
          setSignUpFormFields({ ...signUpFormFields, id: data?.data?.id });
          setIsOTPModalOpen(true);
          toast.success(data.message, { id: "signUp" });
        } else {
          toast.error(data.message, { id: "signUp" });
        }
      } catch (err) {
        console.error(err);
      } finally {
        setSubmitButtonLoading(false);
      }
    }
  };

  // onSubmitHandler
  // const onSubmitHandler = async (e) => {
  //   e.preventDefault();
  //   if (errorHandler()) {
  //     try {
  //       setSubmitButtonLoading(true);
  //       const sendData = { ...signUpFormFields, role: roleOfPerson };
  //       const { data } = await personSignUp(sendData);
  //       if (data?.code === 200) {
  //         toast.success(data?.message, { id: "signUp" });
  //       } else {
  //         toast.error(data?.message, { id: "signUp" });
  //       }
  //     } catch (err) {
  //       console.error(err);
  //     } finally {
  //       setSubmitButtonLoading(false);
  //     }
  //   }
  // };

  //********************************** Handle HTML CODE *****************************//
  return (
    <>
      <div className="auth login">
        <div className="image_wrap">
          <img src={authImg} alt="" />
        </div>

        {/*------------------- Handle signUp component ------------------*/}
        {!isOTPModalOpen && (
          <div className="form_wrap">
            <h3>Create an Account</h3>

            <p>Welcome to brand meet to explore the new thing</p>

            <form>
              <div className="form_field">
                <Input
                  type="text"
                  name="name"
                  onChange={onChangeHandler}
                  className="form-control input"
                  value={signUpFormFields?.name}
                  placeholder="Your name/Trade name"
                  error={signUpFormFieldsValidationError?.name}
                />

                <img src={profileIcon} alt="" className="icon" />
              </div>

              <div className="form_field">
                <Input
                  type="email"
                  name="email"
                  placeholder="Enter email "
                  onChange={onChangeHandler}
                  className="form-control input"
                  extraError={formInfoError?.email}
                  value={signUpFormFields?.email}
                  error={signUpFormFieldsValidationError?.email}
                />
                <img src={messageIcon} alt="" className="icon" />
              </div>

              <div className="form_field">
                <Input
                  type="text"
                  name="mobile"
                  maxLength={10}
                  minLength={10}
                  onChange={onChangeHandler}
                  placeholder="Mobile Number"
                  className="form-control input"
                  extraError={formInfoError?.mobile}
                  value={signUpFormFields?.mobile}
                  error={signUpFormFieldsValidationError?.mobile}
                />
                <img src={callIcon} alt="" className="icon" />
              </div>

              <div className="form_field">
                <Input
                  name="password"
                  type={passwordType}
                  placeholder="Password"
                  onChange={onChangeHandler}
                  extraError={formInfoError?.password}
                  value={signUpFormFields?.password}
                  className="form-control input password"
                  error={signUpFormFieldsValidationError?.password}
                />
                <img src={lockIcon} alt="" className="icon" />
                <span className="password_icons">{showIcon}</span>
              </div>

              <div className="gender_button_wrap">
                <div className="radio_button">
                  <input
                    type="radio"
                    id="customer"
                    name="userType"
                    checked={roleOfPerson === "user"}
                    onChange={() => setRoleOfPerson("user")}
                  />
                  <label htmlFor="customer">
                    <LuUsers />
                    Customer
                  </label>
                </div>

                <div className="radio_button">
                  <input
                    id="Vendor"
                    type="radio"
                    name="userType"
                    checked={roleOfPerson === "vendor"}
                    onChange={() => setRoleOfPerson("vendor")}
                  />
                  <label htmlFor="Vendor">
                    <LuUsers />
                    Vendor
                  </label>
                </div>
              </div>
              <p className="singPolicy">
                <input
                  id="t&c"
                  type="checkbox"
                  checked={isTermsAccepted}
                  onChange={(e) => setIsTermsAccepted(e.target.checked)}
                />
                <label htmlFor="t&c">
                  By clicking on this, you agree to MVLOAD's
                  <Link to="">Terms & Conditions </Link> and {" "}
                  <Link to="">Privacy Policy</Link>
                </label>
              </p>

              <div className="button_wrap">
                {isTermsAccepted ? (
                  <>
                    {submitButtonLoading ? (
                      <LoadingButton
                        disabled
                        className={"w-100 disabled"}
                        loading={submitButtonLoading}
                      />
                    ) : (
                      <Button className={"w-100"} onClick={sendOTPHandler}>
                        Create Account
                      </Button>
                    )}
                  </>
                ) : (
                  <Button isDisabled={true} className="w-100 disabled">
                    Create Account
                  </Button>
                )}
              </div>

              <p className="move_singUp">
                Don't have an account?
                <button type="button" onClick={switchLogin}>
                  Log In
                </button>
              </p>
            </form>
          </div>
        )}

        {/*-------------------- Handle OTP verify component -------------------*/}
        {isOTPModalOpen && (
          <OTPComponent
            callRole={"signUp"}
            closeOTPModal={setIsOTPModalOpen}
            goToBackStep={handleOtpModalVisibility}
            dataToSend={{ ...signUpFormFields, role: roleOfPerson }}
          />
        )}
      </div>
    </>
  );
};

export default SignUp;
