import { Select } from "components/form";
import React from "react";

import Pagination from "react-js-pagination";

const CustomPagination = ({
  limitRow,
  activePage,
  setLimitRow,
  totalRecord,
  handlePageChange,
}) => {
  // Calculate record sow par page handler
  const calculateRecordOnPerPage = (page = activePage, limit = limitRow) => {
    let numberOfRecord = (page - 1) * limit + 1;
    let pageWiseLimit = limit * page;
    return `${numberOfRecord}-${pageWiseLimit} of ${totalRecord}`;
  };

  return (
    <div className="pagination_wrapper">
      <div className="pages_count">
        <p>
          <span>Rows per pages</span>{" "}
          <Select
            value={limitRow}
            onChange={(e) => setLimitRow(e.target.value)}
          >
            <option value={5}>05</option>
            <option value={10}>10</option>
            <option value={15}>15</option>
            <option value={20}>20</option>
          </Select>
        </p>
        <span className="page_tag">{calculateRecordOnPerPage()}</span>
      </div>
      <Pagination
        itemClass="page-item"
        linkClass="page-link"
        pageRangeDisplayed={5}
        activePage={activePage}
        // prevPageText={"Prev"}
        // nextPageText={"Next"}
        // itemClassPrev={"prev"}
        // itemClassNext={"next"}
        onChange={handlePageChange}
        itemsCountPerPage={limitRow}
        totalItemsCount={totalRecord}
        itemClassLast={"last_page next"}
        itemClassFirst={"first_page prev"}
      />
    </div>
  );
};

export default CustomPagination;
