import React, { useContext, useEffect, useState } from "react";
import { Button } from "../../../components/form";
import OTPInput from "react-otp-input";
import { RiEdit2Line } from "react-icons/ri";
import {
  otpSendApi,
  verifyOTPAndLoginAPI,
  verifyOtpAndRegisterAPI,
} from "../../../services/auth-controllers/authCont";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { encryptDataHandler, setDataToLocal } from "../../../config/utils";
import { GlobalUserContext } from "context/GlobalContext";

const OTPComponent = ({
  dataToSend,
  numberChange,
  closeOTPModal,
  goToBackStep,
  callRole = "",
}) => {
  const { setIsLoginModalOpen } = useContext(GlobalUserContext);

  //***************************** Handle All states ***********************************//

  const [otp, setOtp] = useState("");
  const [timer, setTimer] = useState(59);
  const [resendButtonLoading, setResendButtonLoading] = useState(false);

  console.log("DATA TO SEND", dataToSend);

  console.log(`${process.env.REACT_APP_PROVIDER_URL}`);

  //****************************** Handle Hooks *************************//
  const navigate = useNavigate();

  //******************************** Handle All Function ******************************//
  // useEffect for resend otp timer
  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [timer]);

  // verify otp and register user handler function
  const verifyOTPAndRegister = async () => {
    try {
      const sendData = { ...dataToSend, otp: Number(otp) };
      const { data } = await verifyOtpAndRegisterAPI(sendData);
      if (data.code === 200) {
        const dataForLocal = {
          token: data?.data?.token,
          personType: {
            isUser: data?.data?.user?.isUser,
            isVendor: data?.data?.user?.isVendor,
          },
          userDetails: {
            userId: data?.data?.user?.id,
            name: data?.data?.user?.name,
            email: data?.data?.user?.email,
            lastLogin: data?.data?.user?.lastLoginAt,
          },
        };
        if (data?.data?.user?.isUser) {
          setDataToLocal("authDetail", dataForLocal);
        } else {
          setDataToLocal("vendorAuthDetail", dataForLocal);
        }

        if (data?.data?.user?.isUser) {
          navigate(`/user/dashboard/home`);
          toast.success(data?.message);
        } else {
          //-------------- Local Code -----------//
          // const encryptedData = encodeURIComponent(
          //   encryptDataHandler(dataForLocal)
          // );

          // window.open(
          //   `${process.env.REACT_APP_PROVIDER_URL}home?home=${encryptedData}`,
          //   "_blank"
          // );

          //------------ Code for Build -----------//
          // setDataToLocal("vendorAuthDetail", dataForLocal);
          window.open(`${process.env.REACT_APP_PROVIDER_URL}home`, "_blank");

          toast.success(data?.message);
          window.location.reload();
        }
      } else {
        toast.error(data?.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoginModalOpen(false);
    }
  };

  // verify Otp and Login
  const verifyOTPAndLogin = async () => {
    try {
      const sendData = {
        otp,
        mobile: dataToSend?.mobile,
      };

      const { data } = await verifyOTPAndLoginAPI(sendData);
      if (data?.code === 200) {
        const dataForLocal = {
          token: data?.data?.token,
          personType: {
            isUser: data?.data?.user?.isUser,
            isVendor: data?.data?.user?.isVender,
          },
          userDetails: {
            userId: data?.data?.user?.id,
            name: data?.data?.user?.name,
            email: data?.data?.user?.email,
            lastLogin: data?.data?.user?.lastLoginAt,
          },
        };

        if (data?.data?.user?.isUser) {
          setDataToLocal("authDetail", dataForLocal);
        } else {
          setDataToLocal("vendorAuthDetail", dataForLocal);
        }
        if (data?.data?.user?.isUser) {
          navigate(`/user/dashboard/home`);
          toast.success(data?.message);
        } else {
          //-------------- Local Code -----------//
          // const encryptedData = encodeURIComponent(
          //   encryptDataHandler(dataForLocal)
          // );

          // window.open(
          //   `${process.env.REACT_APP_PROVIDER_URL}home?home=${encryptedData}`,
          //   "_blank"
          // );

          //------------ Code for Build -----------//
          // setDataToLocal("vendorAuthDetail", dataForLocal);
          window.open(`${process.env.REACT_APP_PROVIDER_URL}home`, "_blank");

          toast.success(data?.message);
          window.location.reload();
        }
      } else {
        toast.error(data?.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoginModalOpen(false);
    }
  };

  // Resend OTP handler
  const reSendOTPHandler = async (e) => {
    e.preventDefault();
    try {
      setResendButtonLoading(true);
      const { data } = await otpSendApi({ email: dataToSend?.email });
      if (data.code === 200) {
        toast.success(data.message, { id: "signUp" });
        setResendButtonLoading(false);
        setIsLoginModalOpen(false);
      } else {
        toast.error(data.message, { id: "signUp" });
      }
    } catch (err) {
      console.error(err);
      setResendButtonLoading(false);
      setIsLoginModalOpen(false);
    }
  };

  // useEffect for handle onSubmit by role
  useEffect(() => {
    if (otp?.length === 4) {
      if (callRole === "signUp") {
        verifyOTPAndRegister();
      } else {
        verifyOTPAndLogin();
      }
    }
  }, [otp]);

  //*************************** Handle HTML Code *****************************//
  return (
    <>
      <form className="form_wrap otp_section">
        <h3>Verify OTP</h3>
        <p>
          {callRole === "signUp"
            ? `Enter the OTP we sent- ${dataToSend?.mobile || ""}`
            : dataToSend?.type === "mobile"
            ? `Enter the OTP we sent- ${dataToSend?.mobile}`
            : `Enter the OTP we sent- ${dataToSend?.mobile}`}
          <button
            type="button"
            className="editNumber"
            onClick={() =>
              callRole === "signUp" ? goToBackStep() : numberChange()
            }
          >
            <RiEdit2Line />
          </button>
        </p>

        <div className="content">
          <div className="otp_wrap">
            <OTPInput
              value={otp}
              numInputs={4}
              inputType="tel"
              onChange={setOtp}
              inputStyle={"form-control input"}
              renderInput={(props) => <input {...props} />}
            />
            {/* {error.otp && otp.length < 4 && (
              <p className="d-block invalid-feedback">{error.otp}</p>
            )} */}
          </div>

          <div className="resend_button">
            <p className={timer > 0 && "disabled"}>
              Don’t receive the OTP?
              {timer > 0 ? (
                <b>&nbsp;{` ${timer} Sec`}</b>
              ) : resendButtonLoading ? (
                <button disabled type="button">
                  Please Wait...
                </button>
              ) : (
                <button type="button" onClick={reSendOTPHandler}>
                  Resend
                </button>
              )}
            </p>
          </div>

          {/* <div className="button_wrap button_right">
            <Button
              className={"w-100"}
              type="submit"
              onClick={verifyOtpHandler}
              {...submitButtonElement}
            >
              Verify
            </Button>
          </div> */}
        </div>
      </form>
    </>
  );
};

export default OTPComponent;
