import React, { useContext, useEffect, useState } from "react";
import OrderPickupDetails from "./section/OrderPickupDetails";
import { Link, useNavigate } from "react-router-dom";
import {
  completedIcon,
  currentIcon,
  defaultIcon,
} from "../../../../../assets/icons";
import OrderDeliveryDetails from "./section/OrderDeliveryDetails";
import OrderReturnDetails from "./section/OrderReturnDetails";
import ProductDetails from "./section/ProductDetails";
import OrderPaymentDetails from "./section/OrderPaymentDetail";
import CustomModal from "../../../../../components/modals/CustomModal";
import { colorCardImg, colorWalletImg } from "../../../../../assets/images";
import { Button, DatePicker, Input } from "../../../../../components/form";
import { GlobalOrderContext } from "../../../../../context/OrderContext";
import { decryptData, getDataToLocal } from "../../../../../config/utils";
import { Accordion } from "react-bootstrap";
import { ImCancelCircle } from "react-icons/im";
import moment from "moment";

const OrderSummary = () => {
  const navigate = useNavigate();

  //********************** Handle all state *****************//
  const {
    vendorDetail,
    setVendorDetail,
    setOrderDetailsForm,
    orderSearchFieldData,
    orderDetailsForPayment,
    setOrderSearchFieldData,
    setOrderDetailsForPayment,
    extraPickUpChargesWithFloor,
    setExtraPickUpChargesWithFloor,
    extraDeliveryChargesWithFloor,
    setExtraDeliveryChargesWithFloor,
    vendorDetailsWithExtraCharge,
    setVendorDetailsWithExtraCharge,
    extraChargesDelivery,
    setExtraChargesDelivery,

    selectedPickUpFloorChargeName,
    setSelectedPickUpFloorChargeName,
    selectedDeliveryFloorChargeName,
    setSelectedDeliveryFloorChargeName,
  } = useContext(GlobalOrderContext);

  const [stepper, setStepper] = useState("default");
  const [orderSteps, setOrderStep] = useState("pickup");
  // const [orderSteps, setOrderStep] = useState("payment");

  const [payNowModal, setPayNowModal] = useState(false);

  const [orderFormSteppers, setOrderFormSteppers] = useState({
    prevKey: [],
    PrevStatus: "",
    currentKey: "pickup",
    currentStatus: "current", //-------- default/current/completed -------//
  });

  const [orderActualPriceValue, setOrderActualPriceValue] = useState({
    gst: "",
    gst_Amount: "",
    totalAmount: "",
    taxableAmount: "",
  });

  const [appointmentActualPriceValue, setAppointmentActualPriceValue] =
    useState({
      gst: "",
      gst_Amount: "",
      totalAmount: "",
      taxableAmount: "",
    });

  //********************** Handle all functions *****************//

  // Function for handler status bar or order form
  const statusBarHandler = (callRole) => {
    let updateStatusState = {
      currentKey: "",
      currentStatus: "",
      prevKey: [],
    };

    let prevKeyClone = orderFormSteppers?.prevKey?.map((el) => el);

    if (callRole === "pickup") {
      updateStatusState = {
        currentKey: "delivery",
        currentStatus: "current",
        prevKey: ["pickup"],
      };
      setOrderStep("delivery");
    }

    if (callRole === "delivery") {
      updateStatusState = {
        currentKey: "return",
        currentStatus: "current",
        prevKey: [...prevKeyClone, "delivery"],
      };
      setOrderStep("return");
    }

    if (callRole === "return") {
      updateStatusState = {
        currentKey: "details",
        currentStatus: "current",
        prevKey: [...prevKeyClone, "return"],
      };
      setOrderStep("details");
    }

    if (callRole === "details") {
      updateStatusState = {
        currentKey: "payment",
        currentStatus: "current",
        prevKey: [...prevKeyClone, "details"],
      };
      setOrderStep("payment");
    }

    setOrderFormSteppers({ ...updateStatusState });
  };

  // useEffect for set order and vendor details global state if refresh
  useEffect(() => {
    const setGlobalDetailsHandler = () => {
      let vendorData = {};
      if (getDataToLocal("vendorDetailsForOrder")) {
        const vendorDecryptedData =
          getDataToLocal("vendorDetailsForOrder") || {};
        vendorData = decryptData(decodeURIComponent(vendorDecryptedData));

        setOrderActualPriceValue({
          gst: vendorData?.gst,
          gst_Amount: vendorData?.gst_Amount,
          totalAmount: vendorData?.totalAmount,
          taxableAmount: vendorData?.taxableAmount,
        });

        if (!vendorDetail?.vendorId || !orderSearchFieldData?.userId) {
          const orderDecryptedData = getDataToLocal("orderSearchDetail");
          const orderData = decryptData(decodeURIComponent(orderDecryptedData));

          setVendorDetail(vendorData);

          setOrderSearchFieldData(orderData);
        }
        const dataFromLocal = getDataToLocal("authDetail");
        const userDetails = dataFromLocal?.userDetails;
        setOrderDetailsForPayment({
          name: userDetails?.name,
          email: userDetails?.email,
          // mobile: 9897490141,
          orderAmount: vendorData?.totalAmount || 0,
        });
      }
      setGlobalDetailsHandler();
    };

    // eslint-disable-next-line
  }, [getDataToLocal("vendorDetailsForOrder")]);

  useEffect(() => {
    if (getDataToLocal("vendorDetailsForOrder")) {
      const vendorDecryptedData = getDataToLocal("vendorDetailsForOrder");
      let vendorData = decryptData(decodeURIComponent(vendorDecryptedData));
      setOrderActualPriceValue({
        gst: vendorData?.gst,
        gst_Amount: vendorData?.gst_Amount,
        totalAmount: vendorData?.totalAmount,
        taxableAmount: vendorData?.taxableAmount,
      });

      setAppointmentActualPriceValue({
        gst: vendorData?.gst,
        gst_Amount: vendorData?.gst_Amount,
        totalAmount: vendorData?.totalAmount,
        taxableAmount: vendorData?.taxableAmount,
      });
    }
  }, [getDataToLocal("vendorDetailsForOrder")]);

  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     // Prevent the default behavior (optional, for legacy browsers)
  //     event.preventDefault();
  //     event.returnValue = ""; // Required for modern browsers to trigger the event
  //     // setShowModal(true); // Show the modal when the refresh attempt is detected
  //   };

  //   // Add the beforeunload event listener
  //   window.addEventListener("beforeunload", handleBeforeUnload);

  //   // Cleanup the event listener
  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, []);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Trigger the browser's confirmation dialog
      event.preventDefault();
      event.returnValue = ""; // Required for the confirmation dialog

      // Save the intent to reload in localStorage
      localStorage.setItem("reloadRedirect", "true");
    };

    // Check on page load if a redirect is needed
    if (localStorage.getItem("reloadRedirect") === "true") {
      localStorage.removeItem("reloadRedirect"); // Clear the flag
      navigate("/user/dashboard/book-orders"); // Redirect after reload
    }

    // Add the beforeunload event listener
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [navigate]);

  // calculate  Pick up extra Charges Handler
  const extraChargeOnChangeHandler = (e, callType) => {
    if (callType === "pickUp") {
      const { name, checked } = e.target;
      if (name === "mallCharge") {
        if (checked) {
          setVendorDetailsWithExtraCharge({
            ...vendorDetailsWithExtraCharge,
            mallPickUp: parseFloat(vendorDetail?.mallCharge),
          });

          let newTaxableAmt =
            parseFloat(vendorDetail?.taxableAmount) +
            parseFloat(vendorDetail?.mallCharge);

          let newGstAmount = (newTaxableAmt * vendorDetail?.gst) / 100;

          let newTotalAmt = newTaxableAmt + newGstAmount;

          setVendorDetail({
            ...vendorDetail,
            taxableAmount: newTaxableAmt,
            gst_Amount: newGstAmount,
            totalAmount: newTotalAmt,
            V_totalAmount: newTotalAmt,
            V_taxableAmount: newTaxableAmt,
          });

          setOrderActualPriceValue({
            gst_Amount: newGstAmount,
            totalAmount: newTotalAmt,
            taxableAmount: newTaxableAmt,
          });
        } else {
          setVendorDetailsWithExtraCharge({
            ...vendorDetailsWithExtraCharge,
            mallPickUp: null,
          });

          let newTaxableAmt =
            parseFloat(vendorDetail?.taxableAmount) -
            parseFloat(vendorDetail?.mallCharge);
          let newGstAmount = (newTaxableAmt * vendorDetail?.gst) / 100;

          let newTotalAmt = newTaxableAmt + newGstAmount;

          setVendorDetail({
            ...vendorDetail,
            taxableAmount: newTaxableAmt,
            gst_Amount: newGstAmount,
            totalAmount: newTotalAmt,
            V_totalAmount: newTotalAmt,
            V_taxableAmount: newTaxableAmt,
          });

          setOrderActualPriceValue({
            gst_Amount: newGstAmount,
            totalAmount: newTotalAmt,
            taxableAmount: newTaxableAmt,
          });
        }

        setExtraPickUpChargesWithFloor({
          ...extraPickUpChargesWithFloor,
          isMallPickUp: e.target.checked,
        });
      }

      if (name === "csdCharge") {
        if (checked) {
          setVendorDetailsWithExtraCharge({
            ...vendorDetailsWithExtraCharge,
            csdPickUp: parseFloat(vendorDetail?.csdCharge),
          });

          let newTaxableAmt =
            parseFloat(vendorDetail?.taxableAmount) +
            parseFloat(vendorDetail?.csdCharge);

          let newGstAmount = (newTaxableAmt * vendorDetail?.gst) / 100;

          let newTotalAmt = newTaxableAmt + newGstAmount;

          setVendorDetail({
            ...vendorDetail,
            taxableAmount: newTaxableAmt,
            gst_Amount: newGstAmount,
            totalAmount: newTotalAmt,
            V_totalAmount: newTotalAmt,
            V_taxableAmount: newTaxableAmt,
          });

          setOrderActualPriceValue({
            gst_Amount: newGstAmount,
            totalAmount: newTotalAmt,
            taxableAmount: newTaxableAmt,
          });
        } else {
          setVendorDetailsWithExtraCharge({
            ...vendorDetailsWithExtraCharge,
            csdPickUp: null,
          });

          let newTaxableAmt =
            parseFloat(vendorDetail?.taxableAmount) -
            parseFloat(vendorDetail?.csdCharge);
          let newGstAmount = (newTaxableAmt * vendorDetail?.gst) / 100;

          let newTotalAmt = newTaxableAmt + newGstAmount;

          setVendorDetail({
            ...vendorDetail,
            taxableAmount: newTaxableAmt,
            gst_Amount: newGstAmount,
            totalAmount: newTotalAmt,
            V_totalAmount: newTotalAmt,
            V_taxableAmount: newTaxableAmt,
          });

          setOrderActualPriceValue({
            gst_Amount: newGstAmount,
            totalAmount: newTotalAmt,
            taxableAmount: newTaxableAmt,
          });
        }

        setExtraPickUpChargesWithFloor({
          ...extraPickUpChargesWithFloor,
          isCsdPickUp: e.target.checked,
        });
      }

      if (name === "sundayCharge") {
        if (checked) {
          setExtraPickUpChargesWithFloor({
            ...extraPickUpChargesWithFloor,
            isSundayPickUp: checked,
          });
        }
        if (!checked) {
          setExtraPickUpChargesWithFloor({
            ...extraPickUpChargesWithFloor,
            isSundayPickUp: false,
          });
        }

        if (!checked && extraPickUpChargesWithFloor?.pickupsundayDate) {
          setVendorDetailsWithExtraCharge({
            ...vendorDetailsWithExtraCharge,
            sundayPickUp: null,
          });

          let newTaxableAmt =
            parseFloat(vendorDetail?.taxableAmount) -
            parseFloat(vendorDetail?.sundayCharge);
          let newGstAmount = (newTaxableAmt * vendorDetail?.gst) / 100;

          let newTotalAmt = newTaxableAmt + newGstAmount;

          setVendorDetail({
            ...vendorDetail,
            taxableAmount: newTaxableAmt,
            gst_Amount: newGstAmount,
            totalAmount: newTotalAmt,
            V_totalAmount: newTotalAmt,
            V_taxableAmount: newTaxableAmt,
          });

          setExtraPickUpChargesWithFloor({
            ...extraPickUpChargesWithFloor,
            isSundayPickUp: false,
            pickupsundayDate: null,
          });
        }
      }

      // if (name === "sundayCharge") {
      //   if (checked) {
      //     setVendorDetailsWithExtraCharge({
      //       ...vendorDetailsWithExtraCharge,
      //       sundayPickUp: parseFloat(vendorDetail?.sundayCharge),
      //     });

      //     let newTaxableAmt =
      //       parseFloat(vendorDetail?.taxableAmount) +
      //       parseFloat(vendorDetail?.sundayCharge);

      //     let newGstAmount = (newTaxableAmt * vendorDetail?.gst) / 100;

      //     let newTotalAmt = newTaxableAmt + newGstAmount;

      //     setVendorDetail({
      //       ...vendorDetail,
      //       taxableAmount: newTaxableAmt,
      //       gst_Amount: newGstAmount,
      //       totalAmount: newTotalAmt,
      //       V_totalAmount: newTotalAmt,
      //       V_taxableAmount: newTaxableAmt,
      //     });

      //     setOrderActualPriceValue({
      //       gst_Amount: newGstAmount,
      //       totalAmount: newTotalAmt,
      //       taxableAmount: newTaxableAmt,
      //     });
      //   } else {
      //     setVendorDetailsWithExtraCharge({
      //       ...vendorDetailsWithExtraCharge,
      //       sundayPickUp: null,
      //     });

      //     let newTaxableAmt =
      //       parseFloat(vendorDetail?.taxableAmount) -
      //       parseFloat(vendorDetail?.sundayCharge);
      //     let newGstAmount = (newTaxableAmt * vendorDetail?.gst) / 100;

      //     let newTotalAmt = newTaxableAmt + newGstAmount;

      //     setVendorDetail({
      //       ...vendorDetail,
      //       taxableAmount: newTaxableAmt,
      //       gst_Amount: newGstAmount,
      //       totalAmount: newTotalAmt,
      //       V_totalAmount: newTotalAmt,
      //       V_taxableAmount: newTaxableAmt,
      //     });

      //     setOrderActualPriceValue({
      //       gst_Amount: newGstAmount,
      //       totalAmount: newTotalAmt,
      //       taxableAmount: newTaxableAmt,
      //     });
      //   }
      //   setExtraPickUpChargesWithFloor({
      //     ...extraPickUpChargesWithFloor,
      //     isSundayPickUp: e.target.checked,
      //   });
      // }
    }
  };

  // calculate delivery extra Charges Handler
  const deliveryExtraChargeOnChangeHandler = (e, callType) => {
    if (callType === "delivery") {
      const { name, checked } = e.target;

      if (name === "mallCharge") {
        if (checked) {
          setVendorDetailsWithExtraCharge({
            ...vendorDetailsWithExtraCharge,
            mallDelivery: parseFloat(vendorDetail?.mallCharge),
          });

          let newTaxableAmt =
            parseFloat(vendorDetail?.taxableAmount) +
            parseFloat(vendorDetail?.mallCharge);

          let newGstAmount = (newTaxableAmt * vendorDetail?.gst) / 100;

          let newTotalAmt = newTaxableAmt + newGstAmount;

          setVendorDetail({
            ...vendorDetail,
            taxableAmount: newTaxableAmt,
            gst_Amount: newGstAmount,
            totalAmount: newTotalAmt,
            V_totalAmount: newTotalAmt,
            V_taxableAmount: newTaxableAmt,
          });

          setOrderActualPriceValue({
            gst_Amount: newGstAmount,
            totalAmount: newTotalAmt,
            taxableAmount: newTaxableAmt,
          });

          // setAppointmentActualPriceValue({
          //   gst_Amount: newGstAmount,
          //   totalAmount: newTotalAmt,
          //   taxableAmount: newTaxableAmt,
          // });
        } else {
          setVendorDetailsWithExtraCharge({
            ...vendorDetailsWithExtraCharge,
            mallDelivery: null,
          });

          let newTaxableAmt =
            parseFloat(vendorDetail?.taxableAmount) -
            parseFloat(vendorDetail?.mallCharge);
          let newGstAmount = (newTaxableAmt * vendorDetail?.gst) / 100;

          let newTotalAmt = newTaxableAmt + newGstAmount;

          setVendorDetail({
            ...vendorDetail,
            taxableAmount: newTaxableAmt,
            gst_Amount: newGstAmount,
            totalAmount: newTotalAmt,
            V_totalAmount: newTotalAmt,
            V_taxableAmount: newTaxableAmt,
          });

          setOrderActualPriceValue({
            gst_Amount: newGstAmount,
            totalAmount: newTotalAmt,
            taxableAmount: newTaxableAmt,
          });

          // setAppointmentActualPriceValue({
          //   gst_Amount: newGstAmount,
          //   totalAmount: newTotalAmt,
          //   taxableAmount: newTaxableAmt,
          // });
        }

        setExtraDeliveryChargesWithFloor({
          ...extraDeliveryChargesWithFloor,
          isMallDelivery: e.target.checked,
        });
      }

      if (name === "csdCharge") {
        if (checked) {
          setVendorDetailsWithExtraCharge({
            ...vendorDetailsWithExtraCharge,
            csdDelivery: parseFloat(vendorDetail?.csdCharge),
          });

          let newTaxableAmt =
            parseFloat(vendorDetail?.taxableAmount) +
            parseFloat(vendorDetail?.csdCharge);

          let newGstAmount = (newTaxableAmt * vendorDetail?.gst) / 100;

          let newTotalAmt = newTaxableAmt + newGstAmount;

          setVendorDetail({
            ...vendorDetail,
            taxableAmount: newTaxableAmt,
            gst_Amount: newGstAmount,
            totalAmount: newTotalAmt,
            V_totalAmount: newTotalAmt,
            V_taxableAmount: newTaxableAmt,
          });

          setOrderActualPriceValue({
            gst_Amount: newGstAmount,
            totalAmount: newTotalAmt,
            taxableAmount: newTaxableAmt,
          });

          // setAppointmentActualPriceValue({
          //   gst_Amount: newGstAmount,
          //   totalAmount: newTotalAmt,
          //   taxableAmount: newTaxableAmt,
          // });
        } else {
          setVendorDetailsWithExtraCharge({
            ...vendorDetailsWithExtraCharge,
            csdDelivery: null,
          });

          let newTaxableAmt =
            parseFloat(vendorDetail?.taxableAmount) -
            parseFloat(vendorDetail?.csdCharge);
          let newGstAmount = (newTaxableAmt * vendorDetail?.gst) / 100;

          let newTotalAmt = newTaxableAmt + newGstAmount;

          setVendorDetail({
            ...vendorDetail,
            taxableAmount: newTaxableAmt,
            gst_Amount: newGstAmount,
            totalAmount: newTotalAmt,
            V_totalAmount: newTotalAmt,
            V_taxableAmount: newTaxableAmt,
          });

          setOrderActualPriceValue({
            gst_Amount: newGstAmount,
            totalAmount: newTotalAmt,
            taxableAmount: newTaxableAmt,
          });

          // setAppointmentActualPriceValue({
          //   gst_Amount: newGstAmount,
          //   totalAmount: newTotalAmt,
          //   taxableAmount: newTaxableAmt,
          // });
        }

        setExtraDeliveryChargesWithFloor({
          ...extraDeliveryChargesWithFloor,
          isCsdDelivery: e.target.checked,
        });
      }

      if (name === "sundayCharge") {
        if (checked) {
          setExtraDeliveryChargesWithFloor({
            ...extraDeliveryChargesWithFloor,
            isSundayDelivery: checked,
          });
        }
        if (!checked) {
          setExtraDeliveryChargesWithFloor({
            ...extraDeliveryChargesWithFloor,
            isSundayDelivery: false,
          });
        }

        if (!checked && extraDeliveryChargesWithFloor?.deliverysundayDate) {
          setVendorDetailsWithExtraCharge({
            ...vendorDetailsWithExtraCharge,
            sundayDelivery: null,
          });

          let newTaxableAmt =
            parseFloat(vendorDetail?.taxableAmount) -
            parseFloat(vendorDetail?.sundayCharge);
          let newGstAmount = (newTaxableAmt * vendorDetail?.gst) / 100;

          let newTotalAmt = newTaxableAmt + newGstAmount;

          setVendorDetail({
            ...vendorDetail,
            taxableAmount: newTaxableAmt,
            gst_Amount: newGstAmount,
            totalAmount: newTotalAmt,
            V_totalAmount: newTotalAmt,
            V_taxableAmount: newTaxableAmt,
          });

          setExtraDeliveryChargesWithFloor({
            ...extraDeliveryChargesWithFloor,
            isSundayDelivery: false,
            deliverysundayDate: null,
          });
        }
      }

      // if (name === "sundayCharge") {
      //   if (checked) {
      //     setVendorDetailsWithExtraCharge({
      //       ...vendorDetailsWithExtraCharge,
      //       sundayDelivery: parseFloat(vendorDetail?.sundayCharge),
      //     });

      //     let newTaxableAmt =
      //       parseFloat(vendorDetail?.taxableAmount) +
      //       parseFloat(vendorDetail?.sundayCharge);

      //     let newGstAmount = (newTaxableAmt * vendorDetail?.gst) / 100;

      //     let newTotalAmt = newTaxableAmt + newGstAmount;

      //     setVendorDetail({
      //       ...vendorDetail,
      //       taxableAmount: newTaxableAmt,
      //       gst_Amount: newGstAmount,
      //       totalAmount: newTotalAmt,
      //       V_totalAmount: newTotalAmt,
      //       V_taxableAmount: newTaxableAmt,
      //     });

      //     setOrderActualPriceValue({
      //       gst_Amount: newGstAmount,
      //       totalAmount: newTotalAmt,
      //       taxableAmount: newTaxableAmt,
      //     });

      //     // setAppointmentActualPriceValue({
      //     //   gst_Amount: newGstAmount,
      //     //   totalAmount: newTotalAmt,
      //     //   taxableAmount: newTaxableAmt,
      //     // });
      //   } else {
      //     setVendorDetailsWithExtraCharge({
      //       ...vendorDetailsWithExtraCharge,
      //       sundayDelivery: null,
      //     });

      //     let newTaxableAmt =
      //       parseFloat(vendorDetail?.taxableAmount) -
      //       parseFloat(vendorDetail?.sundayCharge);
      //     let newGstAmount = (newTaxableAmt * vendorDetail?.gst) / 100;

      //     let newTotalAmt = newTaxableAmt + newGstAmount;

      //     setVendorDetail({
      //       ...vendorDetail,
      //       taxableAmount: newTaxableAmt,
      //       gst_Amount: newGstAmount,
      //       totalAmount: newTotalAmt,
      //       V_totalAmount: newTotalAmt,
      //       V_taxableAmount: newTaxableAmt,
      //     });

      //     setOrderActualPriceValue({
      //       gst_Amount: newGstAmount,
      //       totalAmount: newTotalAmt,
      //       taxableAmount: newTaxableAmt,
      //     });

      //     // setAppointmentActualPriceValue({
      //     //   gst_Amount: newGstAmount,
      //     //   totalAmount: newTotalAmt,
      //     //   taxableAmount: newTaxableAmt,
      //     // });
      //   }
      //   setExtraDeliveryChargesWithFloor({
      //     ...extraDeliveryChargesWithFloor,
      //     isSundayDelivery: e.target.checked,
      //   });
      // }
    }
  };

  // onChange Pickup Floor Charge handler
  const pickUpFloorChargeAddOnChangeHandler = (e, el) => {
    setSelectedPickUpFloorChargeName({ ...el });
    const { minCharge, charge } = el;
    const totalBoxes = orderSearchFieldData?.items.reduce(
      (total, item) => total + parseInt(item.boxes, 10),
      0
    );
    let newAmount = parseFloat(totalBoxes) * parseFloat(charge);

    if (newAmount > minCharge) {
      setVendorDetailsWithExtraCharge({
        ...vendorDetailsWithExtraCharge,
        floorPickUp: newAmount,
      });

      let newTaxableAmt =
        parseFloat(orderActualPriceValue?.taxableAmount) +
        parseFloat(newAmount);

      let newGstAmount = (parseFloat(newTaxableAmt) * vendorDetail?.gst) / 100;

      let newTotalAmt = parseFloat(newTaxableAmt) + parseFloat(newGstAmount);

      setVendorDetail({
        ...vendorDetail,
        taxableAmount: newTaxableAmt,
        gst_Amount: newGstAmount,
        totalAmount: newTotalAmt,
        V_totalAmount: newTotalAmt,
        V_taxableAmount: newTaxableAmt,
      });
    } else {
      setVendorDetailsWithExtraCharge({
        ...vendorDetailsWithExtraCharge,
        floorPickUp: minCharge,
      });

      let newTaxableAmt =
        parseFloat(orderActualPriceValue?.taxableAmount) +
        parseFloat(minCharge);

      let newGstAmount = (parseFloat(newTaxableAmt) * vendorDetail?.gst) / 100;

      let newTotalAmt = parseFloat(newTaxableAmt) + parseFloat(newGstAmount);

      setVendorDetail({
        ...vendorDetail,
        taxableAmount: newTaxableAmt,
        gst_Amount: newGstAmount,
        totalAmount: newTotalAmt,
        V_totalAmount: newTotalAmt,
        V_taxableAmount: newTaxableAmt,
      });
    }
  };

  // Remove Floor charge From Total handler
  const removePickUpFloorChargeHandler = () => {
    const { minCharge, charge } = selectedPickUpFloorChargeName;
    const totalBoxes = orderSearchFieldData?.items.reduce(
      (total, item) => total + parseInt(item.boxes, 10),
      0
    );
    let newAmount = parseFloat(totalBoxes) * parseFloat(charge);

    if (newAmount > minCharge) {
      setVendorDetailsWithExtraCharge({
        ...vendorDetailsWithExtraCharge,
        floorPickUp: null,
      });

      let newTaxableAmt =
        parseFloat(vendorDetail?.taxableAmount) - parseFloat(newAmount);

      let newGstAmount = (parseFloat(newTaxableAmt) * vendorDetail?.gst) / 100;

      let newTotalAmt = parseFloat(newTaxableAmt) + parseFloat(newGstAmount);

      setVendorDetail({
        ...vendorDetail,
        taxableAmount: newTaxableAmt,
        gst_Amount: newGstAmount,
        totalAmount: newTotalAmt,
        V_totalAmount: newTotalAmt,
        V_taxableAmount: newTaxableAmt,
      });
    } else {
      setVendorDetailsWithExtraCharge({
        ...vendorDetailsWithExtraCharge,
        floorPickUp: null,
      });

      let newTaxableAmt =
        parseFloat(vendorDetail?.taxableAmount) - parseFloat(minCharge);

      let newGstAmount = (parseFloat(newTaxableAmt) * vendorDetail?.gst) / 100;

      let newTotalAmt = parseFloat(newTaxableAmt) + parseFloat(newGstAmount);

      setVendorDetail({
        ...vendorDetail,
        taxableAmount: newTaxableAmt,
        gst_Amount: newGstAmount,
        totalAmount: newTotalAmt,
        V_totalAmount: newTotalAmt,
        V_taxableAmount: newTaxableAmt,
      });
    }

    setSelectedPickUpFloorChargeName({
      ...selectedPickUpFloorChargeName,
      floorName: null,
    });
  };

  // onChange Delivery Floor Charge handler
  const deliveryFloorChargeAddOnChangeHandler = (e, el) => {
    setSelectedDeliveryFloorChargeName({ ...el });
    const { minCharge, charge } = el;
    const totalBoxes = orderSearchFieldData?.items.reduce(
      (total, item) => total + parseInt(item.boxes, 10),
      0
    );
    let newAmount = parseFloat(totalBoxes) * parseFloat(charge);

    if (newAmount > minCharge) {
      setVendorDetailsWithExtraCharge({
        ...vendorDetailsWithExtraCharge,
        floorDelivery: newAmount,
      });

      let newTaxableAmt =
        parseFloat(orderActualPriceValue?.taxableAmount) +
        parseFloat(newAmount);

      let newGstAmount = (parseFloat(newTaxableAmt) * vendorDetail?.gst) / 100;

      let newTotalAmt = parseFloat(newTaxableAmt) + parseFloat(newGstAmount);

      setVendorDetail({
        ...vendorDetail,
        taxableAmount: newTaxableAmt,
        gst_Amount: newGstAmount,
        totalAmount: newTotalAmt,
        V_totalAmount: newTotalAmt,
        V_taxableAmount: newTaxableAmt,
      });

      setAppointmentActualPriceValue({
        gst_Amount: newGstAmount,
        totalAmount: newTotalAmt,
        taxableAmount: newTaxableAmt,
      });
    } else {
      setVendorDetailsWithExtraCharge({
        ...vendorDetailsWithExtraCharge,
        floorDelivery: minCharge,
      });

      let newTaxableAmt =
        parseFloat(orderActualPriceValue?.taxableAmount) +
        parseFloat(minCharge);

      let newGstAmount = (parseFloat(newTaxableAmt) * vendorDetail?.gst) / 100;

      let newTotalAmt = parseFloat(newTaxableAmt) + parseFloat(newGstAmount);

      setVendorDetail({
        ...vendorDetail,
        taxableAmount: newTaxableAmt,
        gst_Amount: newGstAmount,
        totalAmount: newTotalAmt,
        V_totalAmount: newTotalAmt,
        V_taxableAmount: newTaxableAmt,
      });

      setAppointmentActualPriceValue({
        gst_Amount: newGstAmount,
        totalAmount: newTotalAmt,
        taxableAmount: newTaxableAmt,
      });
    }
  };

  // Remove Floor Delivery charge From Total handler
  const removeDeliveryFloorChargeHandler = () => {
    const { minCharge, charge } = selectedDeliveryFloorChargeName;
    const totalBoxes = orderSearchFieldData?.items.reduce(
      (total, item) => total + parseInt(item.boxes, 10),
      0
    );

    let newAmount = parseFloat(totalBoxes) * parseFloat(charge);

    if (newAmount > minCharge) {
      setVendorDetailsWithExtraCharge({
        ...vendorDetailsWithExtraCharge,
        floorDelivery: null,
      });

      let newTaxableAmt =
        parseFloat(vendorDetail?.taxableAmount) - parseFloat(newAmount);

      let newGstAmount = (parseFloat(newTaxableAmt) * vendorDetail?.gst) / 100;

      let newTotalAmt = parseFloat(newTaxableAmt) + parseFloat(newGstAmount);

      setVendorDetail({
        ...vendorDetail,
        taxableAmount: newTaxableAmt,
        gst_Amount: newGstAmount,
        totalAmount: newTotalAmt,
        V_totalAmount: newTotalAmt,
        V_taxableAmount: newTaxableAmt,
      });

      setAppointmentActualPriceValue({
        gst_Amount: newGstAmount,
        totalAmount: newTotalAmt,
        taxableAmount: newTaxableAmt,
      });
    } else {
      setVendorDetailsWithExtraCharge({
        ...vendorDetailsWithExtraCharge,
        floorDelivery: null,
      });

      let newTaxableAmt =
        parseFloat(vendorDetail?.taxableAmount) - parseFloat(minCharge);

      let newGstAmount = (parseFloat(newTaxableAmt) * vendorDetail?.gst) / 100;

      let newTotalAmt = parseFloat(newTaxableAmt) + parseFloat(newGstAmount);

      setVendorDetail({
        ...vendorDetail,
        taxableAmount: newTaxableAmt,
        gst_Amount: newGstAmount,
        totalAmount: newTotalAmt,
        V_totalAmount: newTotalAmt,
        V_taxableAmount: newTaxableAmt,
      });

      setAppointmentActualPriceValue({
        gst_Amount: newGstAmount,
        totalAmount: newTotalAmt,
        taxableAmount: newTaxableAmt,
      });
    }

    setSelectedDeliveryFloorChargeName({
      ...selectedPickUpFloorChargeName,
      floorName: null,
    });
  };

  // check appointment date is sunday
  const appointmentDateChecker = () => {
    const appointmentDay = moment(
      extraChargesDelivery?.selectedAppointmentDate
    ).format("ddd");

    if (appointmentDay === "Sun") {
      return true;
    } else {
      return false;
    }
  };

  // sunday pick upp date calculation handler
  const sundayPickupDateOnChangeHandler = (date) => {
    console.log("DATE FROM FUNCTION", date);

    if (extraPickUpChargesWithFloor?.isSundayPickUp && date) {
      if (vendorDetailsWithExtraCharge?.sundayPickUp > 0) {
        let sundayCharge = vendorDetail?.sundayCharge;

        let newVTax =
          parseFloat(vendorDetail?.taxableAmount) - parseFloat(sundayCharge);

        let newTaxableAmt =
          parseFloat(newVTax) + parseFloat(vendorDetail?.sundayCharge);

        let newGstAmount = (newTaxableAmt * vendorDetail?.gst) / 100;

        let newTotalAmt = newTaxableAmt + newGstAmount;

        setVendorDetail({
          ...vendorDetail,
          taxableAmount: newTaxableAmt,
          gst_Amount: newGstAmount,
          totalAmount: newTotalAmt,
          V_totalAmount: newTotalAmt,
          taxableAmount: newTaxableAmt,
        });

        setVendorDetailsWithExtraCharge({
          ...vendorDetailsWithExtraCharge,
          sundayPickUp: parseFloat(vendorDetail?.sundayCharge),
        });
      } else {
        let newTaxableAmt =
          parseFloat(vendorDetail?.taxableAmount) +
          parseFloat(vendorDetail?.sundayCharge);

        let newGstAmount = (newTaxableAmt * vendorDetail?.gst) / 100;

        let newTotalAmt = newTaxableAmt + newGstAmount;

        setVendorDetail({
          ...vendorDetail,
          taxableAmount: newTaxableAmt,
          gst_Amount: newGstAmount,
          totalAmount: newTotalAmt,
          V_totalAmount: newTotalAmt,
          V_taxableAmount: newTaxableAmt,
        });

        setVendorDetailsWithExtraCharge({
          ...vendorDetailsWithExtraCharge,
          sundayPickUp: parseFloat(vendorDetail?.sundayCharge),
        });
      }

      setExtraPickUpChargesWithFloor({
        ...extraPickUpChargesWithFloor,
        pickupsundayDate: date,
      });
    }
  };

  // Sunday delivery date onChange Handler
  const sundayDeliveryDateOnChangeHandler = (date) => {
    console.log("DATE FROM FUNCTION", date);

    if (extraDeliveryChargesWithFloor?.isSundayDelivery && date) {
      if (vendorDetailsWithExtraCharge?.sundayDelivery > 0) {
        let sundayCharge = vendorDetail?.sundayCharge;

        let newVTax =
          parseFloat(vendorDetail?.taxableAmount) - parseFloat(sundayCharge);

        let newTaxableAmt =
          parseFloat(newVTax) + parseFloat(vendorDetail?.sundayCharge);

        let newGstAmount = (newTaxableAmt * vendorDetail?.gst) / 100;

        let newTotalAmt = newTaxableAmt + newGstAmount;

        setVendorDetail({
          ...vendorDetail,
          taxableAmount: newTaxableAmt,
          gst_Amount: newGstAmount,
          totalAmount: newTotalAmt,
          V_totalAmount: newTotalAmt,
          V_taxableAmount: newTaxableAmt,
        });

        setVendorDetailsWithExtraCharge({
          ...vendorDetailsWithExtraCharge,
          sundayDelivery: parseFloat(vendorDetail?.sundayCharge),
        });
      } else {
        let newTaxableAmt =
          parseFloat(vendorDetail?.taxableAmount) +
          parseFloat(vendorDetail?.sundayCharge);

        let newGstAmount = (newTaxableAmt * vendorDetail?.gst) / 100;

        let newTotalAmt = newTaxableAmt + newGstAmount;

        setVendorDetail({
          ...vendorDetail,
          taxableAmount: newTaxableAmt,
          gst_Amount: newGstAmount,
          totalAmount: newTotalAmt,
          V_totalAmount: newTotalAmt,
          V_taxableAmount: newTaxableAmt,
        });

        setVendorDetailsWithExtraCharge({
          ...vendorDetailsWithExtraCharge,
          sundayDelivery: parseFloat(vendorDetail?.sundayCharge),
        });
      }

      setExtraDeliveryChargesWithFloor({
        ...extraDeliveryChargesWithFloor,
        deliverysundayDate: date,
      });
    }
  };

  console.log("ORDER ACTUAL PRICE ====>>>", orderActualPriceValue);

  console.log(
    "APPOINTMENT ACTUAL PRICE ------->>>>",
    appointmentActualPriceValue
  );

  // Function to calculate the next available Sunday
  const getNextSunday = () => {
    const today = new Date();
    const nextSunday = new Date(
      today.setDate(today.getDate() + ((7 - today.getDay()) % 7))
    );
    return nextSunday;
  };

  // Function to calculate the next available Sunday For Delivery After Tat
  const getNextDeliverySunday = () => {
    const today = new Date(vendorDetail?.ExpectedDelivery);
    const nextSunday = new Date(
      today.setDate(today.getDate() + ((7 - today.getDay()) % 7))
    );
    return nextSunday;
  };

  //********************** Handle HTML CODE *****************//
  return (
    <>
      <div className="order_pickup_details">
        <ul className="bread_crumb">
          <li>
            <Link to={-1}>Book Orders</Link>
          </li>
          <li>Order Summary</li>
        </ul>
        <div className="stepper">
          <ul>
            <li>
              <span>Pickup details</span>
              <b>
                {orderFormSteppers?.currentKey === "pickup" &&
                orderFormSteppers?.currentStatus === "current" ? (
                  <img src={currentIcon} alt="" />
                ) : orderFormSteppers?.prevKey?.includes("pickup") ? (
                  <img src={completedIcon} alt="" />
                ) : (
                  <img src={defaultIcon} alt="" />
                )}
              </b>
            </li>
            <li>
              <span>Delivery Details</span>
              <b>
                {/* {stepper === "completed" && <img src={completedIcon} alt="" />}
                {stepper === "current" && <img src={currentIcon} alt="" />}
                {stepper === "default" && <img src={defaultIcon} alt="" />} */}

                {orderFormSteppers?.currentKey === "delivery" &&
                orderFormSteppers?.currentStatus === "current" ? (
                  <img src={currentIcon} alt="" />
                ) : orderFormSteppers?.prevKey?.includes("delivery") ? (
                  <img src={completedIcon} alt="" />
                ) : (
                  <img src={defaultIcon} alt="" />
                )}
              </b>
            </li>
            <li>
              <span>Return Details</span>
              <b>
                {/* {stepper === "completed" && <img src={completedIcon} alt="" />}
                {stepper === "current" && <img src={currentIcon} alt="" />}
                {stepper === "default" && <img src={defaultIcon} alt="" />} */}

                {orderFormSteppers?.currentKey === "return" &&
                orderFormSteppers?.currentStatus === "current" ? (
                  <img src={currentIcon} alt="" />
                ) : orderFormSteppers?.prevKey?.includes("return") ? (
                  <img src={completedIcon} alt="" />
                ) : (
                  <img src={defaultIcon} alt="" />
                )}
              </b>
            </li>
            <li>
              <span>Details</span>
              <b>
                {/* {stepper === "completed" && <img src={completedIcon} alt="" />}
                {stepper === "current" && <img src={currentIcon} alt="" />}
                {stepper === "default" && <img src={defaultIcon} alt="" />} */}

                {orderFormSteppers?.currentKey === "details" &&
                orderFormSteppers?.currentStatus === "current" ? (
                  <img src={currentIcon} alt="" />
                ) : orderFormSteppers?.prevKey?.includes("details") ? (
                  <img src={completedIcon} alt="" />
                ) : (
                  <img src={defaultIcon} alt="" />
                )}
              </b>
            </li>
            <li>
              <span>Payment Options</span>
              <b>
                {/* {stepper === "completed" && <img src={completedIcon} alt="" />}
                {stepper === "current" && <img src={currentIcon} alt="" />}
                {stepper === "default" && <img src={defaultIcon} alt="" />} */}

                {orderFormSteppers?.currentKey === "payment" &&
                orderFormSteppers?.currentStatus === "current" ? (
                  <img src={currentIcon} alt="" />
                ) : orderFormSteppers?.prevKey?.includes("payment") ? (
                  <img src={completedIcon} alt="" />
                ) : (
                  <img src={defaultIcon} alt="" />
                )}
              </b>
            </li>
          </ul>
        </div>

        <div className="order_summery_wrap">
          <div className="orderDetails _page">
            {orderSteps === "pickup" && (
              <OrderPickupDetails next={() => statusBarHandler("pickup")} />
            )}
            {orderSteps === "delivery" && (
              <OrderDeliveryDetails
                prev={() => setOrderStep("pickup")}
                next={() => statusBarHandler("delivery")}
                orderActualPriceValue={orderActualPriceValue}
                setOrderActualPriceValue={setOrderActualPriceValue}
                appointActual={appointmentActualPriceValue}
                setAppointmentActual={setAppointmentActualPriceValue}
              />
            )}
            {orderSteps === "return" && (
              <OrderReturnDetails
                prev={() => setOrderStep("delivery")}
                next={() => statusBarHandler("return")}
              />
            )}
            {orderSteps === "details" && (
              <ProductDetails
                prev={() => setOrderStep("return")}
                next={() => statusBarHandler("details")}
              />
            )}
            {orderSteps === "payment" && (
              <OrderPaymentDetails
                prev={() => setOrderStep("details")}
                // onClick={() => setOrderStep("return")}
              />
            )}
          </div>

          <div className="order_summery">
            <div className="_page margin_bottom_set">
              <h4>Order Summary</h4>
              <hr />

              <h5>{vendorDetail?.vendorName || "-----"}</h5>
              <p>
                <b>Charges*</b>
              </p>
              <ul>
                {vendorDetailsWithExtraCharge?.mallPickUp > 0 && (
                  <li>
                    <span>Mall Pickup Charge</span>
                    <span>{vendorDetailsWithExtraCharge?.mallPickUp}</span>
                  </li>
                )}

                {vendorDetailsWithExtraCharge?.mallDelivery > 0 && (
                  <li>
                    <span>Mall Delivery Charge</span>
                    <span>{vendorDetailsWithExtraCharge?.mallDelivery}</span>
                  </li>
                )}

                {vendorDetailsWithExtraCharge?.csdPickUp > 0 && (
                  <li>
                    <span>CSD Pickup Charge</span>
                    <span>{vendorDetailsWithExtraCharge?.csdPickUp}</span>
                  </li>
                )}

                {vendorDetailsWithExtraCharge?.csdDelivery > 0 && (
                  <li>
                    <span>CSD Delivery Charge</span>
                    <span>{vendorDetailsWithExtraCharge?.csdDelivery}</span>
                  </li>
                )}

                {vendorDetailsWithExtraCharge?.sundayPickUp > 0 && (
                  <li>
                    <span>Sunday Pickup Charge</span>
                    <span>{vendorDetailsWithExtraCharge?.sundayPickUp}</span>
                  </li>
                )}

                {vendorDetailsWithExtraCharge?.sundayDelivery > 0 && (
                  <li>
                    <span>Sunday Delivery Charge</span>
                    <span>{vendorDetailsWithExtraCharge?.sundayDelivery}</span>
                  </li>
                )}

                {vendorDetailsWithExtraCharge?.floorPickUp && (
                  <li>
                    <span>Pickup Floor Charge</span>
                    <span>{vendorDetailsWithExtraCharge?.floorPickUp}</span>
                  </li>
                )}

                {vendorDetailsWithExtraCharge?.floorDelivery && (
                  <li>
                    <span>Delivery Floor Charge</span>
                    <span>{vendorDetailsWithExtraCharge?.floorDelivery}</span>
                  </li>
                )}

                {vendorDetailsWithExtraCharge?.appointmentDelivery > 0 && (
                  <li>
                    <span>Delivery Appointment Charge</span>
                    <span>
                      {vendorDetailsWithExtraCharge?.appointmentDelivery}
                    </span>
                  </li>
                )}

                <li>
                  <span>Taxable Amount</span>
                  <span>{vendorDetail?.taxableAmount || "------"}</span>
                </li>
                <li>
                  {vendorDetail?.gst ? (
                    <span>GST Amount ({vendorDetail?.gst}%)</span>
                  ) : (
                    <span>GST Amount ("------")</span>
                  )}
                  <span>{vendorDetail?.gst_Amount || "-----"}</span>
                </li>
                <li>
                  <b>Total Amount</b>
                  <b>{vendorDetail?.totalAmount || "-----"}</b>
                </li>
              </ul>
            </div>

            {/*----------- Pick up Type of charges -----------*/}
            {orderSteps === "pickup" &&
              (parseFloat(vendorDetail?.mallCharge) > 0 ||
                parseFloat(vendorDetail?.sundayCharge) > 0 ||
                parseFloat(vendorDetail?.csdCharge) > 0 ||
                vendorDetail?.floorCharge ||
                parseFloat(vendorDetail?.appointmentMin) > 0 ||
                parseFloat(vendorDetail?.appointmentPerKg) > 0) && (
                <div className="_page margin_bottom_set">
                  <h4>Additional Pickup Services</h4>
                  <hr />
                  <ul className="cstm_addi_services">
                    {vendorDetail?.mallCharge > 0 && (
                      <li>
                        <span>Mall Pickup</span>
                        <Input
                          type="checkbox"
                          name="mallCharge"
                          value={extraPickUpChargesWithFloor?.isMallPickUp}
                          checked={extraPickUpChargesWithFloor?.isMallPickUp}
                          onChange={(e) => {
                            extraChargeOnChangeHandler(e, "pickUp");
                          }}
                        />
                      </li>
                    )}

                    {vendorDetail?.csdCharge > 0 && (
                      <li>
                        <span>CSD Pickup</span>
                        <Input
                          type="checkbox"
                          name="csdCharge"
                          value={extraPickUpChargesWithFloor?.isCsdPickUp}
                          checked={extraPickUpChargesWithFloor?.isCsdPickUp}
                          onChange={(e) => {
                            extraChargeOnChangeHandler(e, "pickUp");
                          }}
                        />
                      </li>
                    )}

                    {vendorDetail?.sundayCharge > 0 && (
                      <>
                        <li>
                          <span>Sunday Pickup</span>
                          <Input
                            type="checkbox"
                            name="sundayCharge"
                            value={extraPickUpChargesWithFloor?.isSundayPickUp}
                            checked={
                              extraPickUpChargesWithFloor?.isSundayPickUp
                            }
                            onChange={(e) => {
                              extraChargeOnChangeHandler(e, "pickUp");
                            }}
                          />
                        </li>

                        <li>
                          {extraPickUpChargesWithFloor?.isSundayPickUp && (
                            <div className="form_field">
                              <DatePicker
                                placeholder="dd/mm/yyyy"
                                dateFormat="d MMM, YYYY"
                                label="Sunday Pickup Date*"
                                // minDate={new Date()}
                                minDate={getNextSunday()}
                                filterDate={(date) => date.getDay() === 0}
                                selected={
                                  extraPickUpChargesWithFloor?.pickupsundayDate
                                }
                                onChange={(date) =>
                                  sundayPickupDateOnChangeHandler(date)
                                }
                              />
                            </div>
                          )}
                        </li>
                      </>
                    )}
                  </ul>
                  <hr />

                  {extraPickUpChargesWithFloor?.floorChargesList?.length >
                    0 && (
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          Floor Pickup{" "}
                          {selectedPickUpFloorChargeName?.floorName?.length >
                            0 && (
                            <>
                              <span>
                                ({selectedPickUpFloorChargeName?.floorName}),
                              </span>
                              <ImCancelCircle
                                size={14}
                                fill="red"
                                className="cross_cstm_cut"
                                onClick={() => removePickUpFloorChargeHandler()}
                              />
                            </>
                          )}
                        </Accordion.Header>
                        <Accordion.Body>
                          <ul className="accordian_ul">
                            {extraPickUpChargesWithFloor?.floorChargesList?.map(
                              (el, i) => (
                                <li key={`pickUpFloor${i + 1}`}>
                                  <span>{el?.floorName}</span>

                                  <label htmlFor={`pickUpFloor${i + 1}`}>
                                    {`Rs ${el?.charge}`}
                                    <input
                                      type="radio"
                                      className="mx-1"
                                      name="pickUpFloorName"
                                      id={`pickUpFloor${i + 1}`}
                                      checked={
                                        selectedPickUpFloorChargeName?.floorName ===
                                        el?.floorName
                                      }
                                      onChange={(e) =>
                                        pickUpFloorChargeAddOnChangeHandler(
                                          e,
                                          el
                                        )
                                      }
                                    />
                                  </label>
                                </li>
                              )
                            )}
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  )}
                </div>
              )}

            {/*-------- Delivery Pickup Types of Charges ------*/}
            {orderSteps === "delivery" && (
              <div className="_page margin_bottom_set">
                <h4>Additional Delivery Services</h4>
                <hr />
                <ul className="cstm_addi_services">
                  {vendorDetail?.mallCharge > 0 && (
                    <li>
                      <span>Mall Delivery</span>
                      <Input
                        type="checkbox"
                        name="mallCharge"
                        value={extraDeliveryChargesWithFloor?.isMallDelivery}
                        checked={extraDeliveryChargesWithFloor?.isMallDelivery}
                        onChange={(e) =>
                          deliveryExtraChargeOnChangeHandler(e, "delivery")
                        }
                      />
                    </li>
                  )}

                  {vendorDetail?.csdCharge > 0 && (
                    <li>
                      <span>CSD Delivery</span>
                      <Input
                        type="checkbox"
                        name="csdCharge"
                        value={extraDeliveryChargesWithFloor?.isCsdDelivery}
                        checked={extraDeliveryChargesWithFloor?.isCsdDelivery}
                        onChange={(e) =>
                          deliveryExtraChargeOnChangeHandler(e, "delivery")
                        }
                      />
                    </li>
                  )}

                  {vendorDetail?.sundayCharge > 0 && (
                    <li>
                      <span>Sunday Delivery</span>
                      <Input
                        type="checkbox"
                        name="sundayCharge"
                        disabled={appointmentDateChecker()}
                        className={appointmentDateChecker() ? "disabled" : ""}
                        value={extraDeliveryChargesWithFloor?.isSundayDelivery}
                        checked={
                          extraDeliveryChargesWithFloor?.isSundayDelivery
                        }
                        onChange={(e) =>
                          deliveryExtraChargeOnChangeHandler(e, "delivery")
                        }
                      />
                    </li>
                  )}

                  <li>
                    {extraDeliveryChargesWithFloor?.isSundayDelivery && (
                      <div className="form_field">
                        <DatePicker
                          placeholder="dd/mm/yyyy"
                          dateFormat="d MMM, YYYY"
                          label="Sunday Delivery Date*"
                          minDate={getNextDeliverySunday()}
                          filterDate={(date) => date.getDay() === 0}
                          selected={
                            extraDeliveryChargesWithFloor?.deliverysundayDate
                          }
                          onChange={(date) =>
                            sundayDeliveryDateOnChangeHandler(date)
                          }
                        />
                      </div>
                    )}
                  </li>
                </ul>
                <hr />
                {extraDeliveryChargesWithFloor?.floorChargesList?.length >
                  0 && (
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        Floor Delivery{" "}
                        {selectedDeliveryFloorChargeName?.floorName?.length >
                          0 && (
                          <>
                            <span>
                              ({selectedDeliveryFloorChargeName?.floorName})
                            </span>
                            <ImCancelCircle
                              size={14}
                              fill="red"
                              className="cross_cstm_cut"
                              onClick={() => removeDeliveryFloorChargeHandler()}
                            />
                          </>
                        )}
                      </Accordion.Header>
                      <Accordion.Body>
                        <ul className="accordian_ul">
                          {extraDeliveryChargesWithFloor?.floorChargesList?.map(
                            (el, i) => (
                              <li key={`deliverFloor${i + 1}`}>
                                <span>{el?.floorName}</span>
                                <label htmlFor={`deliverFloor${i + 1}`}>
                                  {`Rs ${el?.charge}`}
                                  <input
                                    type="radio"
                                    className="mx-1"
                                    name="deliveryFloorName"
                                    id={`deliverFloor${i + 1}`}
                                    checked={
                                      selectedDeliveryFloorChargeName?.floorName ===
                                      el?.floorName
                                    }
                                    onChange={(e) =>
                                      deliveryFloorChargeAddOnChangeHandler(
                                        e,
                                        el
                                      )
                                    }
                                  />
                                </label>
                              </li>
                            )
                          )}
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      <CustomModal
        show={payNowModal}
        handleClose={() => setPayNowModal}
        className={"payNowModal"}
      >
        <div className="top_content">
          <img src={colorWalletImg} alt="" />
          <p>
            <b>₹113</b>
            <span>Wallet Balance</span>
          </p>
        </div>
        <ul className="listing">
          <li>
            <p>
              <b>₹1185</b>
              <span>More ways to make payment.</span>
            </p>
            <Button>Add</Button>
            <span className="error_text">
              You need to add ₹113 in your wallet to pay for this order.{" "}
            </span>
          </li>
          <li>
            <p>
              <b>₹1185</b>
              <span>More ways to make payment.</span>
            </p>
            <Button>Continue</Button>
          </li>
        </ul>
      </CustomModal>
    </>
  );
};

export default OrderSummary;
