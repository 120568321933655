import React, { useEffect, useState } from "react";
import MyOrderSummery from "./section/MyOrderSummery";
import OrderReview from "./section/OrderReview";
import { useNavigate, useParams } from "react-router-dom";
import {
  getReviewTypeAPI,
  getVendorDetailsAPI,
  submitReviewAPI,
} from "services/user-controllers/order-controller";
import { toast } from "react-toastify";

const MyOrderReview = () => {
  const navigate = useNavigate();
  const { orderID } = useParams();
  const replacedOrderId = orderID.replace(/~/g, "/");
  //************************** Handle all state **********************//
  const [reviewFields, setReviewFields] = useState({
    orderId: replacedOrderId,
    vendorId: "",
    rating: 0,
    reviewType: "",
    reviewDesc: "",
  });
  const [vendorDetails, setVendorDetails] = useState({});
  const [reviewTypeList, setReviewTypeList] = useState();
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);

  //************************** Handle all functions **********************//
  // get vendorDetailsHandler
  const getVendorDetailsHandler = async () => {
    try {
      const { data } = await getVendorDetailsAPI(replacedOrderId);
      console.log("REVIEW ORDER API RES @@@@@@===--->", data);
      if (data.code === 200) {
        setVendorDetails(data?.data);
      } else {
        toast.error(data?.message);
      }
    } catch (err) {
      console.error(err);
    }
  };

  // get review type listing
  const getReviewTypeListing = async () => {
    try {
      const { data } = await getReviewTypeAPI();
      console.log("REVIEW TYPE API RESS", data);
      if (data?.code === 200) {
        setReviewTypeList(data?.data);
      } else {
        toast.error(data?.message);
      }
    } catch (err) {
      console.error(err);
    }
  };

  // onChangeHandler
  const onChangeHandler = (e, callType) => {
    if (!callType) {
      const { name, value } = e.target;
      setReviewFields({ ...reviewFields, [name]: value });
    } else {
      setReviewFields({ ...reviewFields, rating: e });
    }
  };

  // onSubmitHandler
  const onSubmitHandler = async (e) => {
    e.preventDefault();
    setSubmitButtonLoading(true);
    try {
      let sendData;
      if (vendorDetails?.vendor?.id) {
        sendData = {
          ...reviewFields,
          vendorId: vendorDetails?.vendor?.id,
        };
      } else {
        toast.error("Vendor Id is missing*");
        return;
      }
      const { data } = await submitReviewAPI(sendData);
      if (data?.code === 200) {
        toast.success(data?.message);
        navigate("/user/dashboard/my-orders");
      } else {
        toast.error(data?.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setSubmitButtonLoading(false);
    }
  };

  console.log("REVIEW FIELDS", reviewFields);

  // useEffect for get vendor details
  useEffect(() => {
    getReviewTypeListing();
    getVendorDetailsHandler();

    // eslint-disable-next-line
  }, []);

  // useEffect for set review fields if get
  useEffect(() => {
    if (vendorDetails?.review) {
      const { review } = vendorDetails;
      setReviewFields({
        orderId: review?.Orderid,
        vendorId: review?.vendorId,
        rating: review?.OrderRating,
        reviewType: review?.ReviewType,
        reviewDesc: review?.ReviewDesc,
      });
    }

    // eslint-disable-next-line
  }, [vendorDetails?.review]);

  //************************** Handle HTML CODE **********************//
  return (
    <>
      <div className="my_order_review cstm_order_review_set">
        <div className="dashboard_title">
          <h4>Review</h4>
        </div>

        <div className="content_wrap">
          <MyOrderSummery vendorDetail={vendorDetails} />
          <OrderReview
            reviewField={reviewFields}
            loading={submitButtonLoading}
            onChangeHandle={onChangeHandler}
            onSubmitHandle={onSubmitHandler}
            reviewTypeOptions={reviewTypeList}
            ratingInitial={reviewFields?.rating}
          />
        </div>
      </div>
    </>
  );
};

export default MyOrderReview;
